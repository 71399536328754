<div *ngIf="requestInitiated" class="loading-indicator">
    <mat-progress-spinner diameter="50" mode="indeterminate" color="accent"></mat-progress-spinner>
</div>
<div class="modal add-edit-user-modal">
    <header class="modal-header">
        <span class="modal-title">{{title}}</span>
        <mat-icon class="icon-close" :before (click)="closeModal(false)"></mat-icon>
    </header>
    <div class="mat-dialog-content" id="modal-body">
        <form [formGroup]="createTenantForm" novalidate class="add-edit-form">
            <mat-label class="fields-label required">Tenant Name</mat-label>
            <mat-form-field appearance="fill" class="form-fields-create-edit">
                <input class="create-product-fields" matInput placeholder="" formControlName="name" required>
            </mat-form-field>
            <mat-error class="error-mat-container"
                *ngIf="(createTenantForm.controls.name.errors?.required && createTenantForm.controls.name.dirty) 
            || (createTenantForm.controls.name.pristine && createTenantForm.controls.name.touched && createTenantForm.controls.name.errors?.required)">
                <span class="error-text">
                    Please enter tenant name
                </span>
            </mat-error>
            <mat-label class="fields-label required">Business Contact Email</mat-label>
            <mat-form-field appearance="fill" class="form-fields-create-edit">
                <input class="create-product-fields" matInput placeholder="" formControlName="businessContactEmail"
                    required>
            </mat-form-field>
            <mat-error class="error-mat-container"
                *ngIf="(createTenantForm.controls.businessContactEmail.errors?.required && createTenantForm.controls.businessContactEmail.dirty) 
            || (createTenantForm.controls.businessContactEmail.pristine && createTenantForm.controls.businessContactEmail.touched && createTenantForm.controls.businessContactEmail.errors?.required)">
                <span class="error-text">
                    Please enter business contact email
                </span>
            </mat-error>
            <mat-error class="error-mat-container"
                *ngIf="(createTenantForm.controls.businessContactEmail.errors?.pattern && createTenantForm.controls.businessContactEmail.dirty)">
                <span class="error-text">
                    Please enter valid email address
                </span>
            </mat-error>
            <mat-label class="fields-label required">Technical Contact Email</mat-label>
            <mat-form-field appearance="fill" class="form-fields-create-edit">
                <input class="create-product-fields" matInput placeholder="" formControlName="technicalContactEmail"
                    required>
            </mat-form-field>
            <mat-error class="error-mat-container"
                *ngIf="(createTenantForm.controls.technicalContactEmail.errors?.required && createTenantForm.controls.technicalContactEmail.dirty) 
            || (createTenantForm.controls.technicalContactEmail.pristine && createTenantForm.controls.technicalContactEmail.touched && createTenantForm.controls.technicalContactEmail.errors?.required)">
                <span class="error-text">
                    Please enter technical contact email
                </span>
            </mat-error>
            <mat-error class="error-mat-container"
                *ngIf="(createTenantForm.controls.technicalContactEmail.errors?.pattern && createTenantForm.controls.technicalContactEmail.dirty)">
                <span class="error-text">
                    Please enter valid email address
                </span>
            </mat-error>
            <mat-label class="fields-label required">Authentication Callback Url</mat-label>
            <mat-form-field appearance="fill" class="form-fields-create-edit">
                <input class="create-product-fields" matInput placeholder="" formControlName="authenticationCallbackUrl"
                    required>
            </mat-form-field>
            <mat-error class="error-mat-container"
                *ngIf="(createTenantForm.controls.authenticationCallbackUrl.errors?.required && createTenantForm.controls.authenticationCallbackUrl.dirty) 
            || (createTenantForm.controls.authenticationCallbackUrl.pristine && createTenantForm.controls.authenticationCallbackUrl.touched && createTenantForm.controls.authenticationCallbackUrl.errors?.required)">
                <span class="error-text">
                    Please enter authentication callback url
                </span>
            </mat-error>
            <mat-error class="error-mat-container"
                *ngIf="(createTenantForm.controls.authenticationCallbackUrl.errors?.pattern && createTenantForm.controls.authenticationCallbackUrl.dirty)">
                <span class="error-text">
                    Please enter valid authentication callback url
                </span>
            </mat-error>
            <mat-label class="fields-label">Description</mat-label>
            <mat-form-field appearance="fill" class="form-fields-create-edit-textarea">
                <textarea rows="2" class="create-product-fields-textarea" matInput placeholder=""
                    formControlName="description"></textarea>
            </mat-form-field>
        </form>
    </div>
    <footer class="action-button-layout modal-footer">
        <button *ngIf="editAction" mat-mini-fab class="delete-button" (click)="warnTenantDeletion()">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-stroked-button class="cancel-button" id="modal-action-button"
            (click)="closeModal(false)">Cancel</button>
        <button mat-flat-button class="save-button" [disabled]="!createTenantForm.valid" appDebounceClick
            (debounceClick)="saveTenant()" [debounceTime]="500" color="bcg-button"
            id="modal-cancel-button">Save</button>
    </footer>
</div>