import { Component, OnInit } from '@angular/core';

import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-idle-warning',
  templateUrl: './idle-warning.component.html',
  styleUrls: ['./idle-warning.component.scss']
})
export class IdleWarningComponent implements OnInit {
  countdown: number;
  constructor(
    private idle: Idle
  ) { }

  ngOnInit() {
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.countdown = countdown;
    });
  }

}
