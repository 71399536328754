export class TenantUserModel {
    tenantId: string = null;
    email: string = null;
    firstName: string = null;
    lastName: string = null;
    products?: string = null;
    productIds?: string = null;
    name?: string = null;
    userId?: string = null;
    idpAppId: string = null;
}

export class PagerModel {
    currentPage = 1;
    endIndex = 0;
    endPage = 1;
    pageSize = 10;
    pages = [];
    startIndex = 0;
    startPage = 1;
    totalItems = 0;
    totalPages = 1;
}

export class IdpAppModel {
    id: number = 0;
    appName: string = null;
    displayName: string = null;
    ssoUrl: string = null;
    idpUrl: string = null;
    appCertificate: string = null;
    tenantId: string = null;
    idpAppId: string = null;
    description: string = null;
    samlIssuer: string = null;
    usersCount: number = 0;
    isDefault: boolean = false;
}

export class IdpAppListsModel {
    appName: string = null;
    idpAppId: number = null;
    referenceNotes: string = null;
}

export class EmailDomainListsModel {
    emailDomain: string = null;
    emailDomainId: string = null;
    tenantId: string = null;
    userCount: number = 0;
    idpApps = [];
}