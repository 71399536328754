<div *ngIf="requestInitiated" class="loading-indicator">
    <mat-progress-spinner diameter="50" mode="indeterminate" color="accent"></mat-progress-spinner>
</div>
<div class="modal add-edit-idpapp-modal">
    <header class="modal-header">
        <span class="modal-title">{{title}}</span>
        <mat-icon class="icon-close" :before (click)="closeModal(false)"></mat-icon>
    </header>
    <mat-dialog-content class="mat-dialog-content" id="modal-body">
        <form [formGroup]="addEditEmailIdpForm" novalidate class="add-edit-form">
            <mat-label class="fields-label required">Email Domain</mat-label>
            <mat-form-field appearance="fill" class="form-fields-create-edit">
                <input class="create-product-fields" matInput placeholder="Enter e.g. abc.com" formControlName="domain"
                    required>
            </mat-form-field>
            <mat-error class="error-mat-container"
                *ngIf="(addEditEmailIdpForm.controls.domain.errors?.required && addEditEmailIdpForm.controls.domain.dirty) 
            || (addEditEmailIdpForm.controls.domain.pristine && addEditEmailIdpForm.controls.domain.touched && addEditEmailIdpForm.controls.domain.errors?.required)">
                <span class="error-text">
                    Please enter domain
                </span>
            </mat-error>
            <mat-error class="error-mat-container"
                *ngIf="(addEditEmailIdpForm.controls.domain.errors?.pattern && addEditEmailIdpForm.controls.domain.dirty)">
                <span class="error-text">
                    Please enter valid domain
                </span>
            </mat-error>
            <div class="header-separator">
                <mat-label class="fields-label map-idp required">Map IDP to Domain</mat-label>
            </div>
            <div *ngFor="let association of addEditEmailIdpForm.get('idpAssociations')['controls']; let i = index"
                formArrayName="idpAssociations" class="email-association-form">
                <div [formGroupName]="i" class="email-row">
                    <mat-form-field appearance="fill" class="form-fields-create-edit-email-association display-div">
                        <mat-select [compareWith]="compareFn" matInput placeholder="Select" panelClass="option-panel"
                            id="option-{{i}}" formControlName="idpApp" #idpAppSelector>
                            <mat-option *ngFor="let idpApp of idpTypeList" [value]="idpApp">{{idpApp.appName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-error class="error-mat-container"
                    *ngIf="(addEditEmailIdpForm.controls.idpAssociations.errors?.required && addEditEmailIdpForm.controls.idpAssociations.dirty) 
            || (addEditEmailIdpForm.controls.idpAssociations.pristine && addEditEmailIdpForm.controls.idpAssociations.touched)">
                    <span class="error-text">
                        Please select IDP
                    </span>
                </mat-error>

            </div>
        </form>
    </mat-dialog-content>
    <footer class="action-button-layout modal-footer">
        <button *ngIf="editAction" [disabled]="isEmailDeleteDisabled()" mat-mini-fab class="delete-button"
            appDebounceClick (debounceClick)="deleteEmail()" [debounceTime]="500">
            <mat-icon class="disabled-delete"
                matTooltip="Remove {{totalUserForEmail}} users from IDP(s) to delete Email Domain"
                matTooltipPosition="after" *ngIf='isEmailDeleteDisabled()'>delete</mat-icon>
            <mat-icon *ngIf='!isEmailDeleteDisabled()'>delete</mat-icon>
        </button>
        <button mat-stroked-button class="cancel-button" id="modal-action-button"
            (click)="closeModal(false)">Cancel</button>
        <button mat-flat-button class="save-button" [disabled]="(!addEditEmailIdpForm.valid || duplicateEmail)"
            appDebounceClick (debounceClick)="saveEmailIDP()" [debounceTime]="500" color="bcg-button"
            id="modal-cancel-button">Save</button>
    </footer>
</div>