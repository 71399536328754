import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; /* router module */
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetComponent } from './reset-password/reset-password.component';
import { OktaauthcallbackComponent } from './oktaauthcallback/oktaauthcallback.component';
import { PageNotFoundComponent, SharedModule, UnauthorisedComponent } from './shared';
import { TenantsComponent } from './dashboard/tenants/tenants.component';
import { ManageProductTenantComponent } from './manage-product-tenant/manage-product-tenant.component';
import { IdpAppsComponent } from './manage-product-tenant/idp-apps/idp-apps.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';

/* array of routes which application using across all the pages */
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'tenants', pathMatch: 'full' },
      { path: 'tenants', component: TenantsComponent }
    ]
  },
  {
    path: 'details',
    component: ManageProductTenantComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'idp-apps', pathMatch: 'full' },
      { path: 'idp-apps', component: IdpAppsComponent },
    ]
  },
  {
    path: 'oktaauthcallback',
    component: OktaauthcallbackComponent
  },
  {
    path: 'unauthorised',
    component: UnauthorisedComponent
  },
  {
    path: 'reset-password',
    component: ResetComponent
  },
  {
    path: 'new-password',
    component: NewPasswordComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  /* Below route will redirect all other paths to page not found*/
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), SharedModule],
  exports: [RouterModule],
  declarations: [HomeComponent, PageNotFoundComponent, UnauthorisedComponent, ResetComponent, NewPasswordComponent, LoginComponent, DashboardComponent,
    TenantsComponent, ManageProductTenantComponent,
    IdpAppsComponent,
  ]
})
export class AppRoutingModule { }
