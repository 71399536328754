<div class="account min-height" fxLayout="column" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title fxLayout fxLayoutAlign="center" id="account-title-forgot-password">Sign in to SWP-IAM
    </mat-card-title>

    <mat-card-content>
      <div class="login-container">
        <form [formGroup]="loginForm" novalidate class="login-form">
          <mat-label class="fields-label required">Enter username</mat-label>
          <mat-form-field appearance="fill" class="form-fields-create-edit">
            <input class="create-product-fields" matInput placeholder="Enter username" autocomplete="off"
              formControlName="UserName">
          </mat-form-field>
          <mat-error class="error-mat-container"
            *ngIf="(loginForm.controls.UserName.errors?.required && loginForm.controls.UserName.dirty) || (loginForm.controls.UserName.pristine && loginForm.controls.UserName.touched)">
            <span class="error-text">
              Please enter username
            </span>
          </mat-error>
          <div class="header-separator">
          </div>
          <mat-label class="fields-label required">Enter password</mat-label>
          <mat-form-field appearance="fill" class="form-fields-create-edit">
            <input class="create-product-fields" type="password" matInput placeholder="Enter password"
              formControlName="Password">
          </mat-form-field>
          <mat-error class="error-mat-container"
            *ngIf="(loginForm.controls.Password.errors?.required && loginForm.controls.Password.dirty) || (loginForm.controls.Password.pristine && loginForm.controls.Password.touched)">
            <span class="error-text">
              Please enter password
            </span>
          </mat-error>
          <button appDebounceClick (debounceClick)="login()" [debounceTime]="500" class="btn-success login-button"
            mat-button>Sign in</button>

          <!-- <a class="forgot-password" [routerLink]="['/new-password']">Reset Password</a> -->
        </form>


      </div>
    </mat-card-content>
  </mat-card>
</div>