<div fxLayout="column" fxLayoutAlign="start stretch" class="dashboard-app-container">
    <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="activeLinkIndex" [destroyOnHide]="false" class="nav-tabs">
        <li [ngbNavItem]="tab.index" *ngFor="let tab of tabs">
            <a ngbNavLink [routerLink]="tab.link">{{ tab.name }}</a>
        </li>
    </ul> -->
    <router-outlet></router-outlet>
</div>


