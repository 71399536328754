<div class="tenants-main-layout">
    <div fxLayout="column" fxLayoutAlign="start stretch" class="tenants-app-container" id="tenantsContainer">
        <div class="search-filter-container">
            <div class="layout-1">
                <p class="sub-title">All Tenants</p>
                <div class="search-filter">
                    <div class="search-box">
                        <mat-form-field appearance="fill">
                            <input matInput type="text" class="search-input" placeholder="Search Tenant"
                                [(ngModel)]="searchword" #searchBox
                                (keyup)="searchThisWithDebounce($event.target.value)">
                            <mat-icon matPrefix aria-hidden="false" class="icon-search" :before
                                aria-label="search icon"></mat-icon>
                            <button mat-button *ngIf="searchword" matSuffix mat-icon-button
                                (click)="searchword='' ; searchThis()">
                                <mat-icon class="icon-close" :before></mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="filter-sort">
                        <mat-label class="sort-label">Sort by:</mat-label>
                        <mat-form-field appearance="fill" class="sort-form">
                            <mat-select panelClass="option-panel" placeholder="Select">
                                <mat-option value="A-Z" (click)="sortAscending()">A-Z</mat-option>
                                <mat-option value="Z-A" (click)="sortDescending()">Z-A</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="add layout-2" matTooltip="Add Tenant" matTooltipPosition="after" (click)="addTenant()">

                <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Iconography &amp; Logos/Icons/Download</title>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="IDP-1" transform="translate(-751.000000, -460.000000)" fill="#008040">
                            <g id="Forms-&amp;-Controls/Button/Primary/Primary-CTA"
                                transform="translate(735.000000, 452.000000)">
                                <g id="plus-circle" transform="translate(16.000000, 8.000000)">
                                    <path
                                        d="M12.5,8.63327784 L12.5,7.34872441 C12.5,7.17492013 12.4363333,7.02442295 12.309,6.89723285 C12.1816667,6.77004274 12.031,6.70644769 11.857,6.70644769 L9.286,6.70644769 L9.286,4.13833971 C9.286,3.96453543 9.22233333,3.81403825 9.095,3.68684814 C8.96766667,3.55965804 8.817,3.49606299 8.643,3.49606299 L7.357,3.49606299 C7.183,3.49606299 7.03233333,3.55965804 6.905,3.68684814 C6.77766667,3.81403825 6.714,3.96453543 6.714,4.13833971 L6.714,6.70644769 L4.143,6.70644769 C3.969,6.70644769 3.81833333,6.77004274 3.691,6.89723285 C3.56366667,7.02442295 3.5,7.17492013 3.5,7.34872441 L3.5,8.63327784 C3.5,8.80708211 3.56366667,8.9575793 3.691,9.0847694 C3.81833333,9.21195951 3.969,9.27555456 4.143,9.27555456 L6.714,9.27555456 L6.714,11.8436625 C6.714,12.0174668 6.77766667,12.167964 6.905,12.2951541 C7.03233333,12.4223442 7.183,12.4859393 7.357,12.4859393 L8.643,12.4859393 C8.817,12.4859393 8.96766667,12.4223442 9.095,12.2951541 C9.22233333,12.167964 9.286,12.0174668 9.286,11.8436625 L9.286,9.27555456 L11.857,9.27555456 C12.031,9.27555456 12.1816667,9.21195951 12.309,9.0847694 C12.4363333,8.9575793 12.5,8.80708211 12.5,8.63327784 Z M15.714,7.99100112 C15.714,9.3887604 15.369,10.6779753 14.679,11.8586457 C13.989,13.0393161 13.0533333,13.9739303 11.872,14.6624882 C10.6906667,15.3510461 9.4,15.695658 8,15.6963249 C6.6,15.6969899 5.30933333,15.352378 4.128,14.6624882 C2.94666667,13.9725984 2.011,13.0379843 1.321,11.8586457 C0.631,10.6793071 0.286,9.39009224 0.286,7.99100112 C0.286,6.59191001 0.631,5.30269516 1.321,4.12335658 C2.011,2.944018 2.94666667,2.00940382 4.128,1.31951406 C5.30933333,0.629624297 6.6,0.285012373 8,0.285677327 C9.4,0.286344207 10.6906667,0.63095613 11.872,1.31951406 C13.0533333,2.00807199 13.989,2.94268616 14.679,4.12335658 C15.369,5.304027 15.714,6.59324184 15.714,7.99100112 Z"
                                        id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <p>Add Tenant</p>
            </div>
        </div>
        <!-- <div class="divider"></div> -->
        <div class="content">
            <mat-card *ngFor="let tenant of tenantsList; let i = index" class="tenant-card-layout card-no-{{(i+2)%3}}"
                id="mat-card-{{i}}">
                <mat-card-header>
                    <mat-card-title>{{tenant.name}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="contact-container">
                        <p>
                            <span class="tile-fields-label">Business Contact</span> <br>
                            <!-- <span class="tile-fields-label-value"> {{tenant.businessContactEmail}} </span> -->
                            <a class="contact-decorator" title="{{tenant.businessContactEmail}}"
                                href='mailto: {{tenant.businessContactEmail}}' (click)="$event.stopPropagation()"
                                target="_blank">{{tenant.businessContactEmail}}</a>
                        </p>
                        <p>
                            <span class="tile-fields-label">Technical Contact</span> <br>
                            <a class="contact-decorator" title="{{tenant.technicalContactEmail}}"
                                href='mailto: {{tenant.technicalContactEmail}}' (click)="$event.stopPropagation()"
                                target="_blank">{{tenant.technicalContactEmail}}</a>
                            <!-- <span class="tile-fields-label-value"> {{tenant.technicalContactEmail}} </span> -->
                        </p>
                    </div>
                    <!-- <div class="id-content">
                        <div class="tile-fields-label"> Tenant Id </div>
                        <div class="tile-field-light-value">
                            <span class="field-light">{{tenant.tenantid}}</span>
                            <mat-icon class="content-copy" matTooltip={{copyText}}
                                (click)="copyId(tenant.tenantid); $event.stopPropagation()" matTooltipPosition="after">
                            </mat-icon>
                        </div>
                    </div> -->
                    <div class="key-container">
                        <div class="jwt-container">
                            <div class="id-content">
                                <div class="tile-fields-label"> Tenant Id </div>
                                <div class="tile-field-light-value">
                                    <span class="field-light" title="{{tenant.tenantId}}">{{tenant.tenantId}}</span>
                                    <mat-icon class="content-copy" matTooltip={{copyText}}
                                        (click)="copyId(tenant.tenantId); $event.stopPropagation()"
                                        matTooltipPosition="after">
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                        <div class="jwt-container">
                            <div class="inner-container">
                                JWT Verification Key
                                <mat-icon matTooltip="Download Key"
                                    (click)="downloadKey(tenant.jwtVerificationKey); $event.stopPropagation()"
                                    class="icon-download" :before matTooltipPosition="after">
                                </mat-icon>
                            </div>
                            <div class="api-key-container">
                                API Key
                                <mat-icon matTooltip="Download Key"
                                    (click)="downloadKey(tenant.apiKey, 'APIKey'); $event.stopPropagation()"
                                    class="icon-download" :before matTooltipPosition="after">
                                </mat-icon>
                            </div>
                        </div>

                    </div>
                    <div class="divider"></div>
                    <div class="description"> {{modifyDesc(tenant.description)}}</div>
                    <div class="edit-button-container">
                        <button class="edit-button" (click)="$event.stopPropagation(); tenantDetails(tenant)"
                            mat-flat-button>Edit Tenant</button>
                        <button class="edit-button" (click)="$event.stopPropagation(); manageTenant(tenant)"
                            mat-flat-button>Manage Tenant</button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>