import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { OktaAuthService } from '../okta-auth.service';
import { AppCookieService } from '../services/app.cookie.service';
import { parseJwt } from '../shared/utils/commonfunctions.utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  private subscription;
  oktaObj: any;
  tabs: any[];
  tabLinks: any[];
  activeLinkIndex = 1;
  username = '';
  enableLogout = false;
  timer;
  color1 = '';
  color2 = '';
  constructor(
    private router: Router,
    private elRef: ElementRef,
    private oktaService: OktaAuthService,
    private appCookieService: AppCookieService,
  ) {
    this.tabs = [
      {
        name: 'Tenants',
        link: './dashboard/tenants',
        pathname: '/dashboard/tenants',
        index: 2
      }
    ];
    this.tabLinks = [
      {
        pathname: '/dashboard/tenants',
        index: 2
      },
      {
        pathname: '/new-password',
        index: 3
      }, {
        pathname: '/',
        index: 2
      }
    ];
  }

  ngOnInit() {
    this.setTab();
    this.decodeToken();
  }

  setTab() {
    const obj = this.tabLinks.findIndex((val) => {
      if (window.location.pathname.includes(val.pathname)) {
        return val;
      }
    });
    this.activeLinkIndex = this.tabLinks[obj].index;
  }

  decodeToken() {
    //const token = localStorage.getItem('accessToken');
    const token = this.appCookieService.get('authToken');
    if (token) {
      const base64 = parseJwt(token);
      console.log(base64)
      this.username = base64['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
      // console.log(JSON.parse(jsonPayload))
    }
  }

  showLogout(val) {
    this.enableLogout = val;
    if (this.timer && !this.timer.closed) {
      this.timer.unsubscribe()
    }
    this.timer = interval(5000).pipe(take(1)).subscribe(value => {
      if (this.enableLogout) {
        this.enableLogout = false
      }
    })
  }

  goToResetPassword() {
    this.router.navigate(["/new-password"]);
    this.enableLogout = false;
    this.setTab();
  }

  goToTenants() {
    this.router.navigate(["/dashboard/tenants"]);
    this.enableLogout = false;
    this.setTab();
  }

  logout() {
    localStorage.clear();
    this.appCookieService.remove('authToken');
    window.location.href = window.origin + '/login';
  }
}
