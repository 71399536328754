import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { CommonService } from '../services';
import { AppCookieService } from '../services/app.cookie.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private appCookieService: AppCookieService,
    private commonService: CommonService,
  ) {
    this.loginForm = this.formBuilder.group({
      UserName: ['', [Validators.required]],
      Password: ['', [Validators.required]]
    });
  }
  ngOnInit() {
  }

  validateForm(form: FormGroup): boolean {
    if (!form.valid) {
      /* if you are using angular 8 or above, you can just do form.markAllAsTouched() which will touch 
         all the fields without having to loop through all the fields and mark it as touched.
      */
      form.markAllAsTouched()
      return false
    }
    else {
      return true
    }
  }

  login() {
    if (this.loginForm.valid) {
      console.log(this.loginForm.value);
      const loginObj = {
        UserName: this.commonService.removeHtmlTags(this.loginForm.value.UserName),
        Password: this.commonService.removeHtmlTags(this.loginForm.value.Password)
      }
      this.loginService.authenticateUser(loginObj).subscribe((res: any) => {
        console.log(res);
        if (res && res.token) {
          this.appCookieService.set('authToken', res.token)
          document.location.href = `${window.origin}/dashboard/tenants`;
        }
      },
        err => {
          console.log('errr', err);
          this.toastrService.error('Something went wrong ', err);
        })
    } else {
      return this.validateForm(this.loginForm)
    }
  }

}
