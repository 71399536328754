import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {

    constructor() { }

    log(value: any): void {
        // console.log(value);
    }

    warn(value: any): void {
        console.warn(value);
    }

    error(value: any): void {
        console.error(value);
    }
}
