import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError as observableCatchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { StatusCodes } from '../../shared/utils/enums';
import { StorageService } from '../services';
import { environment } from '../../../environments/environment';
import { LocalLoggerService } from '../../services/local-logger.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private toastr: ToastrService,
        private loggerService: LocalLoggerService,
        private storageService: StorageService,
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req)
            .pipe(
                observableCatchError(this._handleError.bind(this))
            );
    }

    private _handleError(err: HttpErrorResponse) {
        console.log('HttpErrorResponse---', err);
        this.toastr.clear();
        timer(300).subscribe(time => {
            if (typeof err.error === 'string') {
                this.toastr.error(err.error, 'Error');
            }
            else {
                // The backend returned an unsuccessful response code.
                if (err.error && typeof (err.error) === 'object') {
                    if (err.error.length > 0) { // if its an array of errors
                        this.loggerService.logError(`Backend returned code array ${err.status} body was: ${err.error}`);
                        this.toastr.error(err.error, '\nError code - ' + err.status);
                    } else { // token specific errors
                        this.loggerService.logError(`Backend returned code object ${err.status} body was:
                    ${err.message || 'Something went wrong'}`);
                        // this.toastr.error(err.error.message, 'Error - ' + err.error.status); // Commented due to undefined Error field

                        if (err.error && err.error.hasOwnProperty('errors') && Object.values(err.error.errors).length > 0) {
                            this.toastr.error(Object.values(err.error.errors)[0].toString(), 'Error');
                        } else {
                            this.toastr.error(err.error.message, 'Error');
                        }
                    }
                } else {
                    this.loggerService.logError(`Backend returned code ${err.status} body was: ${err.error}`);
                    if (window.location.href.includes('login')) {
                        this.toastr.error(err.error);
                    } else {
                        this.toastr.error(err.message);
                    }
                }
            }

        });
        if (err.status === StatusCodes.UNAUTHORIZED) {
            this.storageService.clearAll();
            if (!window.location.href.includes('login')) {
                document.location.href = window.origin + `/login`;
            }
            return;
        }
        // this.toastr.error(((err.error instanceof Error || err.error instanceof ProgressEvent)
        // ? err.statusText : err.error), 'Error - ' + err.status);
        return observableThrowError({
            status: err.status,
            message: ((err.error) ? err.statusText : err.error)
        });
    }
}
