import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { appConstants } from '../shared';
import { IRegionData } from '../interfaces/region.interface';

@Injectable()
export class CommonService {
  loading: boolean;
  constructor(
    private http: HttpClient
  ) {
  }

  removeHtmlTags(val) {
    if (val && val.length > 0) {
      let output = val.replace(/(<([^>]+)>)/ig, "");
      return output.trim();
    }
  }
}
