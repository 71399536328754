<div class="modal modal-confirm">
	<header class="modal-header">
		<span class="modal-title">{{ data.title }}</span>
		<mat-icon class="icon-close" :before (click)="closeModal(false)"></mat-icon>
	</header>
	<div mat-dialog-content id="modalMsg">
		<!-- <p id="modal-confirm-message2">{{ data.message }}</p> -->
	</div>
	<footer class="action-button-layout modal-footer">
		<button mat-stroked-button class="cancel-button" id="modal-confirm-cancel" *ngIf="buttons.cancel.toShow" mat-button [mat-dialog-close]="false"
			tabindex="1">
			{{ buttons.cancel.label }}
		</button>
		<button mat-flat-button class="save-button"  color="bcg-button" 
			id="modal-confirm-ok" *ngIf="buttons.ok.toShow" mat-button [mat-dialog-close]="true" tabindex="2">
			{{ buttons.ok.label }}
		</button>
	</footer>
</div>
