import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as  OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  // styleUrls: ['./unauthorised.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnauthorisedComponent implements OnInit {
  signIn: any;
  constructor(
    private router: Router
  ) {
    // this.signIn = new OktaSignIn({ baseUrl: 'https://dev-633609.okta.com' });
  }

  ngOnInit() {
    // if(localStorage.getItem('authKey') && localStorage.getItem('authKey') != null && localStorage.getItem('authKey') != undefined){
    //   this.router.navigate(['/home']);
    // }else{
    //   //this.router.navigate(['/unauthorised']);
    // }
    // this.signIn.remove()
    // this.signIn.renderEl({
    //   el: '#widget-container',
    //   authParams: {
    //     issuer: 'https://dev-633609.okta.com/oauth2/default'
    //   }
    // }, (res) => {
    //   if (res.status === 'SUCCESS') {
    //     console.log('Do something with this sessionToken', res, res.session.token);
    //     localStorage.setItem('authKey', res.session.token);
    //     document.querySelector("#widget-container").remove();
    //     this.router.navigate(['/home'])
    //   } else {
    //     // The user can be in another authentication state that requires further action.
    //     // For more information about these states, see:
    //     //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
    //   }
    // });
  }

}
