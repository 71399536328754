import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'stratus-ui';
  isLoginPage = false;
  currentEvent: any;
  constructor(
    private router: Router
  ) {
  }

  async ngOnInit() {
    this._checkForLoginScreen();
  }
  private _checkForLoginScreen() {
    if (window.location.pathname.indexOf('login') >= 0) {
      this.isLoginPage = true;
    } else {
      this.isLoginPage = false;
    }

    this.currentEvent = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
      });
  }
}
