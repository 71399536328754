import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs'; // import reactiveX library for reactive programming using Observables,
import { finalize } from 'rxjs/operators';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { LocalLoggerService } from '../../services/local-logger.service';
import { TokenService, LoggerService } from '../services'; // import authentacation service
import { AppCookieService } from '../../services/app.cookie.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  progressBar: NgProgressRef;
  private _inProgressCount = 0;
  constructor(
    private tokenService: TokenService,
    private logger: LoggerService,
    private loggerService: LocalLoggerService,
    private ngProgress: NgProgress,
    private appCookieService: AppCookieService
  ) {
    this.progressBar = this.ngProgress.ref();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._inProgressCount++;
    if (!this.progressBar.isStarted && !(window.location.href.includes('login') || window.location.href.includes('new-password'))) {
      this.progressBar.start();
    }

    this.loggerService.logForDev(
      `Intercepting HttpRequest of type ${req.method} to url ${req.url}`
    );

    req = req.clone({
      headers: req.headers
        .set('If-Modified-Since', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
    });

    //if (localStorage.getItem('authToken') && localStorage.getItem('authToken') != null
    if (this.appCookieService.get('authToken') && this.appCookieService.get('authToken') != null
      //|| localStorage.getItem('authToken') != undefined && localStorage.getItem('authToken').length > 0) {
      || this.appCookieService.get('authToken') != undefined) {
      //this.loggerService.logForDev('Authorization ', localStorage.getItem('authToken'));
      this.loggerService.logForDev('Authorization ', this.appCookieService.get('authToken'));
      req = req.clone({
        headers: req.headers
          //.set('Authorization', localStorage.getItem('accessToken'))
          .set('Authorization', 'Bearer ' + this.appCookieService.get('authToken'))
      });
    }
    // execute the request
    return next.handle(req).pipe(
      finalize(() => {
        this._inProgressCount--;
        console.log('this._inProgressCount ', this._inProgressCount)
        if (this._inProgressCount == 0) {
          this.progressBar.complete();
        }
      })
    );
  }
}
