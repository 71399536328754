import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { CommonService } from '../services';
import { AppCookieService } from '../services/app.cookie.service';
import { CustomValidators } from '../shared';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private appCookieService: AppCookieService,
    private commonService: CommonService,
  ) {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(12)]],
      currentPassword: ['', [Validators.required, Validators.minLength(5)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(12), this.validateAreEqual.bind(this)]]
    });
  }
  ngOnInit() {
  }

  private validateAreEqual(fieldControl: FormControl) {
    console.log('fieldControl.value ', fieldControl.value)
    return fieldControl.value && fieldControl.value === this.passwordForm.controls["password"].value ? null : {
      NotEqual: true
    };
  }

  setNewPassword() {
    if (this.passwordForm.valid) {
      console.log(this.passwordForm.value);
      const passwordObj = {
        password: this.commonService.removeHtmlTags(this.passwordForm.value.password),
        currentPassword: this.commonService.removeHtmlTags(this.passwordForm.value.currentPassword)
      }
      this.loginService.setNewPassword(passwordObj).subscribe((res: any) => {
        console.log(res);
        this.toastrService.success('Password updated successfully ', 'Success');
        this.router.navigate(['/dashboard/tenants'])
      },
        err => {
          console.log(err)
          // this.router.navigate(['/dashboard/tenants'])
        })
    } else {
      return;
    }
  }

}
