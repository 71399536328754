import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IModalConfirmButtons, IModalConfirmData } from '../../../interfaces';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: 'modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  buttons: IModalConfirmButtons = {
    ok: {
      toShow: true,
      label: 'Continue'
    },
    cancel: {
      toShow: true,
      label: 'Cancel'
    }
  };
  emailDomain: '';
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (typeof this.data.buttons !== 'undefined') {
      Object.assign(this.buttons.ok, this.data.buttons.ok);
      Object.assign(this.buttons.cancel, this.data.buttons.cancel);
    }
    document.getElementById('modalMsg').innerHTML = this.data.message;
  }

  // If the user clicks the cancel button a.k.a. the go back button, then\
  // just close the modal
  closeModal(status) {
    this.dialogRef.close(status);
  }
}
