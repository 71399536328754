import { Injectable } from '@angular/core';
import { appConstants } from '../../shared/config';

@Injectable()
export class StorageService {

    constructor() { }

    /**
     * Fetches and returns the item from specified storage
     * @param key : Key for accessing the stored value
     * @param storageType : specify if the key lookup is to be carried out in local or session storage
     * Import and use appConstants from shared/config.ts to specify the storage type
     * @default : default value for storageType parameter
     */
    getItem(key: string, storageType: string = appConstants.keys.storageType): any {
        switch (storageType) {
            case appConstants.storageTypes.local:
                return JSON.parse(JSON.stringify(localStorage.getItem(key)));
            case appConstants.storageTypes.session:
                return JSON.parse(JSON.stringify(sessionStorage.getItem(key)));
        }
    }

    /**
     * Stores the key value pair in specified storage
     * @param key : Key for identifying the value to be stored
     * @param value : Value to be stored
     * @param storageType : specify if the key lookup is to be carried out in local or session storage.
     * Import and use appConstants from shared/config.ts to specify the storage type
     * @default : default value for storageType parameter
     */
    setItem(key: string, value: any, storageType: string = appConstants.keys.storageType): void {
        switch (storageType) {
            case appConstants.storageTypes.local:
                localStorage.setItem(key, JSON.stringify(value));
                break;
            case appConstants.storageTypes.session:
                sessionStorage.setItem(key, JSON.stringify(value));
                break;
        }
    }

    /**
     * Removes provided key and associated value from specified storage
     * @param key : Key for identifying the value to be removed
     * @param storageType : specify if the key lookup is to be carried out in local or session storage
     * Import and use appConstants from shared/config.ts to specify the storage type
     * @default : default value for storageType parameter
     */
    removeItem(key: string, storageType: string = appConstants.keys.storageType): void {
        switch (storageType) {
            case appConstants.storageTypes.local:
                localStorage.removeItem(key);
                break;
            case appConstants.storageTypes.session:
                sessionStorage.removeItem(key);
                break;
        }
    }

    /**
     * Clears the specified storage
     * @param storageType : specify the storage that is to be cleared
     * Import and use appConstants from shared/config.ts to specify the storage type
     * @default : default value for storageType parameter
     */
    clearAll(storageType: string = appConstants.keys.storageType): void {
        switch (storageType) {
            case appConstants.storageTypes.local:
                localStorage.clear();
                break;
            case appConstants.storageTypes.session:
                sessionStorage.clear();
                break;
        }
    }

}
