import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

import { IUserInfo } from '../../interfaces';
import { RoutePermission } from '../../models';
import { appConstants, AuthorizationResult, RoutePermissionType } from '../../shared';

@Injectable()
export class AuthorizationService {

    constructor(
        private storageService: StorageService
    ) { }

    authorize(routePermission: RoutePermission): AuthorizationResult {
        let isAuthorised = false;
        // tslint:disable-next-line: one-variable-per-declaration
        const token: IUserInfo = this.storageService.getItem(appConstants.keys.token),
            user: IUserInfo = this.storageService.getItem(appConstants.keys.user);

        if (token === null || user === null) {
            return AuthorizationResult.LoginRequired;
        }

        if (routePermission.permissionType === RoutePermissionType.OnlyLoginRequired) {
            isAuthorised = true;
        } else if (routePermission.permissionType === RoutePermissionType.AtLeastOneRole) {
            isAuthorised = user.roles.some((userRole: string) => {
                return routePermission.roles.some((allowedRole: string) => allowedRole.toLowerCase() === userRole.toLowerCase());
            });
        } else if (routePermission.permissionType === RoutePermissionType.AllRole) {
            isAuthorised = user.roles.every((userRole: string) => {
                return routePermission.roles.some((allowedRole: string) => allowedRole.toLowerCase() === userRole.toLowerCase());
            });
        }

        if (isAuthorised) {
            return AuthorizationResult.Success;
        } else {
            return AuthorizationResult.Unauthorised;
        }
    }
}
