<div class="frame">


  <div *ngIf="isLoginPage">
    <ng-progress></ng-progress>
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="!isLoginPage">
    <app-header></app-header>
    <div class="app-container">
      <ng-progress></ng-progress>
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- <app-footer></app-footer> -->
</div>