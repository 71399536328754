import { Pipe, PipeTransform } from '@angular/core';
import { IdpApp, CommonEnums } from '../utils/enums';
@Pipe({
    name: 'idpAppTypeName'
})

export class IdpAppTypeName implements PipeTransform {
    transform(data): string {
        return data === IdpApp.bcgIdpType ? CommonEnums.BCGOktaIdp : CommonEnums.ClientIdp;
    }
}
