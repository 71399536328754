import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RunningMode } from './../shared/utils/enums';

@Injectable()
export class LocalLoggerService {
    constructor(
    ) { }

    logForDev(...args) {
      // console.log(...args);
    }

    logError(...args) {
      console.error(...args);
    }
}

