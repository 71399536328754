<div class="account min-height" fxLayout="column" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title fxLayout fxLayoutAlign="center" id="account-title-forgot-password">Reset Password
    </mat-card-title>

    <mat-card-content>
      <div class="login-container">
        <form [formGroup]="passwordForm" novalidate class="login-form">
          <mat-label class="fields-label required">Enter current password</mat-label>
          <mat-form-field appearance="fill" class="form-fields-create-edit">
            <input class="create-product-fields" type="password" matInput placeholder="Enter password"
              formControlName="currentPassword" required>
          </mat-form-field>
          <mat-error class="error-mat-container"
            *ngIf="(passwordForm.controls.currentPassword.errors?.required && passwordForm.controls.currentPassword.dirty)">
            <span class="error-text">
              Please enter password
            </span>
          </mat-error>
          <mat-error class="error-mat-container"
            *ngIf="(passwordForm.controls.currentPassword.errors?.minlength && passwordForm.controls.currentPassword.dirty)">
            <span class="error-text">
              Password should be atleast 12 characters long
            </span>
          </mat-error>
          <div class="header-separator">
          </div>
          <mat-label class="fields-label required">Enter new password</mat-label>
          <mat-form-field appearance="fill" class="form-fields-create-edit">
            <input class="create-product-fields" type="password" matInput placeholder="Enter password"
              formControlName="password" required>
          </mat-form-field>
          <mat-error class="error-mat-container"
            *ngIf="(passwordForm.controls.password.errors?.required && passwordForm.controls.password.dirty)">
            <span class="error-text">
              Please enter password
            </span>
          </mat-error>
          <mat-error class="error-mat-container"
            *ngIf="(passwordForm.controls.password.errors?.minlength && passwordForm.controls.password.dirty)">
            <span class="error-text">
              Password should be atleast 12 characters long
            </span>
          </mat-error>
          <div class="header-separator">
          </div>
          <mat-label class="fields-label required">Confirm password</mat-label>
          <mat-form-field appearance="fill" class="form-fields-create-edit">
            <input class="create-product-fields" type="password" matInput placeholder="Enter password"
              formControlName="confirmPassword" required>
          </mat-form-field>
          <mat-error class="error-mat-container"
            *ngIf="(passwordForm.controls.confirmPassword.errors?.required && passwordForm.controls.confirmPassword.dirty)">
            <span class="error-text">
              Please enter confirm password
            </span>
          </mat-error>
          <mat-error class="error-mat-container"
            *ngIf="(passwordForm.controls.confirmPassword.errors?.minlength && passwordForm.controls.confirmPassword.dirty)">
            <span class="error-text">
              Confirm password should be atleast 12 characters long
            </span>
          </mat-error>
          <mat-error class="error-mat-container"
            *ngIf="(!passwordForm.controls.confirmPassword.errors?.minlength && passwordForm.controls.confirmPassword.errors?.NotEqual && passwordForm.controls.confirmPassword.dirty)">
            <span class="error-text">
              New password & confirm password should match
            </span>
          </mat-error>
          <button appDebounceClick (debounceClick)="setNewPassword()" [debounceTime]="2500"
            class="btn-success login-button" mat-button>Reset</button>
        </form>


      </div>
    </mat-card-content>
  </mat-card>
</div>