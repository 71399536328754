import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { appConstants } from '../../shared/config';

@Injectable()
export class TokenService {

    constructor(private storageService: StorageService) { }
    /**
     * fetch local stored token and add Bearer to token
     */
    getApiToken(): string {
        let token: string = this.storageService.getItem(appConstants.keys.token);
        if (token !== null) {
            token = 'Bearer ' + token;
        }
        return token;
    }

}
