import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms'; /* while reactive login form used*/

import { PlatformModule } from '@angular/cdk/platform';
import { ObserversModule } from '@angular/cdk/observers';
import { MatListModule } from '@angular/material/list';
import { NgProgressModule } from '@ngx-progressbar/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IdpAppTypeName } from './pipes/idpAppTypeName.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { DebounceClickDirective } from './directives/debounce.directive';

@NgModule({
  declarations: [IdpAppTypeName, FilterPipe, DebounceClickDirective],
  imports: [ToastrModule.forRoot(), CommonModule, FlexLayoutModule,
    MatButtonModule, MatDialogModule, MatFormFieldModule],
  exports: [
    CommonModule,
    IdpAppTypeName,
    FilterPipe,
    DebounceClickDirective,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatInputModule,
    RouterModule,
    FlexLayoutModule,
    NgProgressModule,
    FormsModule,
    ReactiveFormsModule,
    ObserversModule,
    PlatformModule,
    MatCardModule,
    MatDialogModule,
    MatTabsModule,
    NgbModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatListModule
  ]
})
export class SharedModule { }
