import { Injectable } from '@angular/core';
import * as  OktaSignIn from '@okta/okta-signin-widget';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalLoggerService } from './services/local-logger.service';

@Injectable({
  providedIn: 'root'
})
export class OktaAuthService {
  signIn: any;

  constructor(private http: HttpClient,
              private loggerService: LocalLoggerService) { }

  signInWidgetConfig = {
    language: 'en',
    clientId: '0oa21brbassrg9Gdq357',
    redirectUri: window.location.origin + '/oktaauthcallback',
    pkce: true,

    baseUrl: 'https://dev-633609.okta.com',
    authParams: {
      responseType: ['id_token', 'token'],
      display: 'page',
      issuer: 'https://dev-633609.okta.com/oauth2/default',
      response_mode: 'fragment',
      grantType: 'implicit',
      scopes: ['openid', 'email', 'profile', 'address', 'phone']
    },
  };

  setOkta() {
    this.signIn = new OktaSignIn(this.signInWidgetConfig);
    this.loggerService.logForDev(this.signIn);
    return of(this.signIn);
  }
  getOktaProps() {
    this.loggerService.logForDev(this.signIn);
    return of(this.signIn);
  }

  customLogin(obj) {
    this.loggerService.logForDev(obj);
    obj.options = {
      multiOptionalFactorEnroll: false,
      warnBeforePasswordExpired: true
    };
    return this.http.post(`https://dev-633609.okta.com/api/v1/authn`, obj);
  }
}
