import { Component, OnInit, Inject, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdpApp, EmailDomains } from '../../shared/utils/enums';
import { IdpAppModel } from '../../models/product-tenant.model';
import { IIdpApp } from '../../interfaces/product-tenant.interface';
import { ManageProductTenantManagerService } from '../manage-product-tenant.service';
import { ToastrService } from 'ngx-toastr';
import { appConstants, InputRegex } from '../../shared/config';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalConfirmComponent } from '../../shared/components/modal-confirm/modal-confirm.component';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-modal-add-edit-email',
  templateUrl: './modal-add-edit-email.component.html',
  styleUrls: ['./modal-add-edit-email.component.scss']
})

export class ModalAddEditEmailComponent implements OnInit {
  addEditEmailIdpForm: FormGroup;
  title = '';
  clientIdp = false;
  editAction = false;
  requestInitiated = false;
  duplicateEmail = false;
  currentAssociations: FormArray;
  emailDomainRegex = '^([[\\w-.]+\\.[A-Za-z]{2,4}?)+$';
  idpTypeList = [];
  totalUserForEmail = 0;
  defaultIDPData: any;
  constructor(
    private dialogRef: MatDialogRef<ModalAddEditEmailComponent>,
    private manageProductTenantManagerService: ManageProductTenantManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    this.addEditEmailIdpForm = this.formBuilder.group({
      domain: ['', [Validators.required, Validators.pattern(this.emailDomainRegex)]],
      id: [0],
      idpAssociations: this.formBuilder.array([])
    });
  }

  ngOnInit() {
    this.title = this.data.title;
    console.log('this.data ', this.data)
    this.editAction = this.title === EmailDomains.editEmailIDP ? true : false;
    this.idpTypeList = [];
    this.idpTypeList = this.data.idpList;
    this.idpTypeList.forEach(val => val.isNotSelectable = false);
    if (this.editAction) {
      this.patchEditData();
      // this.modifyIDPSelectable();
    } else {
      this.addIDPAssociation(null, true);
    }
  }

  // getter for form
  get getForm() {
    return this.addEditEmailIdpForm.controls;
  }

  // add association to formarray
  addIDPAssociation(idpValue, defaultVal) {
    this.currentAssociations = this.addEditEmailIdpForm.get('idpAssociations') as FormArray;
    this.currentAssociations.push(this.createAssociation(idpValue, defaultVal));
  }

  // create association for idp and default
  createAssociation(idpValue, defaultVal) {
    return this.formBuilder.group({
      idpApp: [idpValue || '', [Validators.required]],
      defaultEmailIdp: [defaultVal || false]
    });
  }

  // patch data to model during edit
  patchEditData() {
    this.addEditEmailIdpForm.patchValue({
      domain: this.data.emailDomainData.domain,
      id: this.data.emailDomainData.id
    });
    this.data.idpList.forEach((ele, index) => {
      if (ele.idpAppId === this.data.emailDomainData.idpAppId) {
        this.defaultIDPData = ele;
        this.addIDPAssociation(ele.idpAppName, ele.isDefault);
        this.currentAssociations.controls[index].get('idpApp').patchValue(ele);
        this.currentAssociations.controls[index].disable();
      }
    });
  }

  // compare for dropdown values
  compareFn(val1: any, val2: any) {
    return val1 && val1 ? val1.idpAppId === val2.idpAppId : val1 === val2;
  }

  // modify idp availibility for dropdown
  modifyIDPSelectable() {
    this.idpTypeList.forEach(val => {
      const res = this.addEditEmailIdpForm.getRawValue().idpAssociations.some(i => {
        if (i.idpApp.idpAppId === val.idpAppId) {
          return true;
        }
        return false;
      });
      val.isNotSelectable = res;
    });
  }

  // send data to API
  saveEmailIDP() {
    if (this.editAction) {
      this.editServerDataEmailIDP();
    } else {
      this.createServerDataEmailIDP();
    }
  }

  // prepare server data for create email domains 
  createServerDataEmailIDP() {
    this.createServerAPIEmailIDP();
  }

  // initiate server data for create email domains 
  createServerAPIEmailIDP() {
    let emailData: any = {};
    emailData.domain = this.addEditEmailIdpForm.value.domain;
    emailData.tenantId = this.data.tenantId;
    emailData.idpAppId = this.addEditEmailIdpForm.getRawValue().idpAssociations[0] && this.addEditEmailIdpForm.getRawValue().idpAssociations[0].idpApp.idpAppId;

    if (this.data.emailDomainList.find(a => a.domain.trim() === emailData.domain.trim())) {
      this.toastrService.error('Email domain already exist', 'Error');
      return;
    }

    this.requestInitiated = true;
    this.manageProductTenantManagerService.createEmailDomain(emailData)
      .subscribe(res => {
        this.requestInitiated = false;
        this.toastrService.success(appConstants.messages.emailDomainAdded, 'Success');
        setTimeout(() => {
          this.closeModal(true);
        }, 1000);
      },
        error => {
          this.requestInitiated = false;
        });
  }


  // prepare server data for edit email domains 
  editServerDataEmailIDP() {
    this.editServerAPIEmailIDP();
  }

  // initiate server data for edit email domains 
  editServerAPIEmailIDP() {
    let emailData: any = {};
    emailData.domain = this.addEditEmailIdpForm.value.domain;
    emailData.id = this.data.emailDomainData.id;
    emailData.idpAppId = this.addEditEmailIdpForm.getRawValue().idpAssociations[0] && this.addEditEmailIdpForm.getRawValue().idpAssociations[0].idpApp.idpAppId;


    if (this.data.emailDomainList.find(a => a.domain.toLowerCase().trim() === emailData.domain.toLowerCase().trim() && a.id != emailData.id)) {
      this.toastrService.error('Email domain already exist', 'Error');
      return;
    }

    this.requestInitiated = true;
    this.manageProductTenantManagerService.editEmailDomain(emailData)
      .subscribe(res => {
        this.requestInitiated = false;
        this.toastrService.success(appConstants.messages.emailDomainAdded, 'Success');
        setTimeout(() => {
          this.closeModal(true);
        }, 1000);
      },
        error => {
          this.requestInitiated = false;
        });
  }

  // check if there is any default idp set and show warn message
  checkDefaultIDPChanged() {
    let result = false
    this.addEditEmailIdpForm.getRawValue().idpAssociations.forEach((val) => {
      if ((val.idpApp.idpAppId === this.defaultIDPData.idpAppId)
        && this.defaultIDPData.isDefault !== val.defaultEmailIdp) {
        result = true;
      }
    });
    return result;
  }

  // check if email domain can be deleted 
  isEmailDeleteDisabled() {
    this.totalUserForEmail = 0;
    this.addEditEmailIdpForm.getRawValue().idpAssociations.forEach((val) => {
      this.totalUserForEmail += parseInt(val.idpApp.userCount ? val.idpApp.userCount : 0);
    });
    return this.totalUserForEmail > 0 ? true : false;
  }

  // default togled change other defaulted as false
  defaultToggled(index) {
    this.currentAssociations.controls.forEach((val, i) => {
      if (i !== index) {
        this.currentAssociations.controls[i].get('defaultEmailIdp').patchValue(false);
      }
    });
  }

  // delete Email Domain API call
  deleteEmail() {
    console.log(this.data.emailDomainData);

    this.requestInitiated = true;
    this.manageProductTenantManagerService.deleteEmailDomain(this.data.emailDomainData.id)
      .subscribe(res => {
        this.requestInitiated = false;
        this.toastrService.success(appConstants.messages.emailDomainDeleted, 'Success');
        setTimeout(() => {
          this.closeModal(true);
        }, 1000);
      },
        error => {
          this.requestInitiated = false;
        });
  }


  // If the user clicks the cancel button a.k.a. the go back button, then\
  // just close the modal
  closeModal(status) {
    this.dialogRef.close(status);
  }
}
