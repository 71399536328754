import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// custom modules
import { CoreModule } from './core';
import { AuthGuard } from './core/guards/auth.guard';
import { OktaauthcallbackComponent } from './oktaauthcallback/oktaauthcallback.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AngularMaterialModule } from './angular-material.module';

// app level services
import { LocalLoggerService, CommonService } from './services';
import { DashboardManagerService } from './dashboard/dashboard-manager.service';
import { ManageProductTenantManagerService } from './manage-product-tenant/manage-product-tenant.service';
import { ModalAddEditEmailComponent } from './manage-product-tenant/modal-add-edit-email/modal-add-edit-email.component';
import { ModalAddEditIdpComponent } from './manage-product-tenant/modal-add-edit-idp/modal-add-edit-idp.component';
import { ModalAddEditTenantComponent } from './dashboard/modal-add-edit-tenant/modal-add-edit-tenant.component';
// app level shared components
import {
  IdleWarningComponent, ModalConfirmComponent, SharedModule
} from './shared';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppCookieService } from './services/app.cookie.service';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    OktaauthcallbackComponent,
    IdleWarningComponent,
    ModalConfirmComponent,
    HeaderComponent,
    FooterComponent,
    ModalAddEditEmailComponent,
    ModalAddEditIdpComponent,
    ModalAddEditTenantComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    CoreModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    // FontAwesomeModule
    SharedModule
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy }, 
    AuthGuard, LocalLoggerService, DashboardManagerService, ManageProductTenantManagerService,
    CommonService, AppCookieService],
  entryComponents: [IdleWarningComponent, ModalConfirmComponent,
    ModalAddEditEmailComponent, ModalAddEditIdpComponent,
    ModalAddEditTenantComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
