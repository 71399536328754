import { Component, OnInit, Inject, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenantUserModel } from '../../models/product-tenant.model';
import { ITenantUser } from '../../interfaces/product-tenant.interface';
import { ITenantList, ITenantUIList } from '../../interfaces/tenant.interface';
import { TenantsListModel, TenantsUIListModel } from '../../models/tenants.model';
import { DashboardManagerService } from '../dashboard-manager.service';
import { LocalLoggerService } from '../../services/local-logger.service';
import { ToastrService } from 'ngx-toastr';
import { appConstants, Email, InputRegex, UrlRegex } from '../../shared/config';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonEnums } from '../../shared/utils/enums';
import { ModalConfirmComponent } from '../../../app/shared/components/modal-confirm/modal-confirm.component';
import { CommonService } from '../../../app/services/common.service';

@Component({
  selector: 'app-modal-add-edit-tenant',
  templateUrl: './modal-add-edit-tenant.component.html',
  styleUrls: ['./modal-add-edit-tenant.component.scss']
})

export class ModalAddEditTenantComponent implements OnInit {
  createTenantForm: FormGroup;
  title = '';
  editAction = false;
  requestInitiated = false;
  constructor(
    private dialogRef: MatDialogRef<ModalAddEditTenantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dashboardManagerService: DashboardManagerService,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private localLoggerService: LocalLoggerService,
    public dialog: MatDialog,
    public commonService: CommonService,
  ) {
    this.createTenantForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(InputRegex)]],
      businessContactEmail: ['', [Validators.required, Validators.pattern(Email)]],
      technicalContactEmail: ['', [Validators.required, Validators.pattern(Email)]],
      authenticationCallbackUrl: ['', Validators.pattern(UrlRegex)],
      idpRelayStateParam: [''],
      description: [''],
      useDefaultIdp: [false],
      tenantId: [''],
    });
  }

  ngOnInit() {
    this.title = this.data.title;
    if (this.title === appConstants.messages.tenantEditLabel) {
      this.editAction = true;
      this.patchValueToForm(this.data.tenantData);
    } else {
      this.editAction = false;
    }
  }

  // patch edit value of product to form fields
  patchValueToForm(val) {
    this.createTenantForm.patchValue({
      name: val.name,
      tenantId: val.tenantId,
      businessContactEmail: val.businessContactEmail,
      technicalContactEmail: val.technicalContactEmail,
      authenticationCallbackUrl: val.authenticationCallbackUrl,
      idpRelayStateParam: val.idpRelayStateParam,
      description: val.description,
      useDefaultIdp: val.useDefaultIdp,
    });
  }

  // send product data to server
  saveTenant() {
    const tenantObj: ITenantList = {
      name: this.commonService.removeHtmlTags(this.createTenantForm.value.name),
      businessContactEmail: this.commonService.removeHtmlTags(this.createTenantForm.value.businessContactEmail),
      technicalContactEmail: this.commonService.removeHtmlTags(this.createTenantForm.value.technicalContactEmail),
      authenticationCallbackUrl: this.commonService.removeHtmlTags(this.createTenantForm.value.authenticationCallbackUrl),
      idpRelayStateParam: this.commonService.removeHtmlTags(this.createTenantForm.value.idpRelayStateParam),
      description: this.commonService.removeHtmlTags(this.createTenantForm.value.description),
      useDefaultIdp: this.commonService.removeHtmlTags(this.createTenantForm.value.useDefaultIdp),
      tenantId: this.commonService.removeHtmlTags(this.createTenantForm.value.tenantId),
    }
    if (this.editAction) {
      this.editTenant(tenantObj);
    } else {
      this.createTenant(tenantObj);
    }
  }

  // create tenant api
  createTenant(obj: ITenantList) {
    this.requestInitiated = true;
    this.dashboardManagerService.createTenant(obj).subscribe((res: any) => {
      this.localLoggerService.logForDev(res);
      this.toastrService.success(appConstants.messages.tenantCreated, 'Success');
      this.createTenantForm.reset();
      this.requestInitiated = false;
      this.closeModal(true);
    },
      error => {
        this.requestInitiated = false;
      });
  }

  // edit tenant api
  editTenant(obj: ITenantList) {
    this.requestInitiated = true;
    this.dashboardManagerService.editTenant(obj).subscribe((res: any) => {
      this.localLoggerService.logForDev(res);
      this.toastrService.success(appConstants.messages.editTenant, 'Success');
      this.createTenantForm.reset();
      this.requestInitiated = false;
      this.closeModal(true);
    },
      error => {
        this.requestInitiated = false;
      });
  }

  warnTenantDeletion() {
    let msg = appConstants.messages.tenantDelete;
    let modalButton: any = null;
    modalButton = {
      ok: {
        toShow: true,
        label: 'Continue'
      },
      cancel: {
        toShow: true,
        label: 'Cancel'
      },
    };
    const dialogRef2: MatDialogRef<ModalConfirmComponent> = this.dialog.open(
      ModalConfirmComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          title: 'Warning!',
          message: msg,
          buttons: modalButton
        }
      });
    dialogRef2.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteTenant();
      }
    });
  }

  // delete tenant api
  deleteTenant() {
    this.dashboardManagerService.deleteTenant(this.data.tenantData.tenantId).subscribe((res: any) => {
      this.toastrService.success(appConstants.messages.tenantDeletedMessage, 'Success');
      this.createTenantForm.reset();
      this.closeModal(true);
    },
      error => {
        this.localLoggerService.logError(error);
      });
  }

  // If the user clicks the cancel button a.k.a. the go back button, then\
  // just close the modal
  closeModal(status) {
    this.dialogRef.close(status);
  }
}
