import { Component, OnInit, Inject, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdpApp } from '../../shared/utils/enums';
import { IdpAppModel } from '../../models/product-tenant.model';
import { IIdpApp } from '../../interfaces/product-tenant.interface';
import { ManageProductTenantManagerService } from '../manage-product-tenant.service';
import { ToastrService } from 'ngx-toastr';
import { appConstants, InputRegex, UrlRegex } from '../../shared/config';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalConfirmComponent } from '../../shared/components/modal-confirm/modal-confirm.component';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-modal-add-edit-idp',
  templateUrl: './modal-add-edit-idp.component.html',
  styleUrls: ['./modal-add-edit-idp.component.scss']
})

export class ModalAddEditIdpComponent implements OnInit {

  addEditIdpForm: FormGroup;
  title = '';
  idpTypeList = [IdpApp.BCGOktaApp, IdpApp.ClientIdp];
  clientIdp = false;
  editAction = false;
  requestInitiated = false;
  enablePemOptions = false;
  isBcgIDPSelected = false;
  isEncryptionEnabledClass = false;

  constructor(
    private dialogRef: MatDialogRef<ModalAddEditIdpComponent>,
    private manageProductTenantManagerService: ManageProductTenantManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    this.addEditIdpForm = this.formBuilder.group({
      appName: ['', Validators.required],
      displayName: ['', Validators.required],
      ssoUrl: ['', [Validators.required, Validators.pattern(UrlRegex)]],
      appCertificate: ['', Validators.required],
      description: [''],
      samlIssuer: ['', Validators.required],
      isDefault: [true],
    });
  }

  ngOnInit() {
    this.title = this.data.title;
    this.editAction = this.title === IdpApp.editIdpApp ? true : false;
    if (this.editAction) {
      this.patchEditData();
    }
  }

  // getter for form
  get getForm() {
    return this.addEditIdpForm.controls;
  }


  // toggle product callback field 
  isEncryptionEnabledToggle(eve) {
    this.enablePemOptions = this.addEditIdpForm.get('isDefault').value ? true : false
    console.log('this. isDefaultvalue ', this.addEditIdpForm.get('isDefault').value);
    if (this.enablePemOptions) {
      this.isEncryptionEnabledClass = true;
    } else {
      this.isEncryptionEnabledClass = false;
    }
  }

  // patch data to model during edit
  patchEditData() {
    this.addEditIdpForm.patchValue({
      ssoUrl: this.data.idpData.ssoUrl,
      samlIssuer: this.data.idpData.samlIssuer,
      description: this.data.idpData.description,
      appCertificate: this.data.idpData.appCertificate,
      appName: this.data.idpData.appName,
      displayName: this.data.idpData.displayName,
      isDefault: this.data.idpData.isDefault,
    });
  }

  // validate Data while saving
  saveIdp() {

    this.requestInitiated = true;
    const idpData: IIdpApp = new IdpAppModel();
    idpData.tenantId = this.data.tenantId;
    idpData.appCertificate = this.commonService.removeHtmlTags(this.addEditIdpForm.value.appCertificate);
    idpData.ssoUrl = this.commonService.removeHtmlTags(this.addEditIdpForm.value.ssoUrl);
    idpData.description = this.commonService.removeHtmlTags(this.addEditIdpForm.value.description);
    idpData.appName = this.sanitizer.sanitize(SecurityContext.HTML, this.addEditIdpForm.value.appName);
    if (this.addEditIdpForm.value.samlIssuer) {
      idpData.samlIssuer = this.commonService.removeHtmlTags(this.addEditIdpForm.value.samlIssuer);
    } else {
      idpData.samlIssuer = idpData.appName;
    }
    idpData.displayName = this.sanitizer.sanitize(SecurityContext.HTML, this.addEditIdpForm.value.displayName);
    idpData.isDefault = this.addEditIdpForm.value.isDefault;
    if (this.editAction) {
      idpData.id = this.data.idpData.id;
      this.editIdp(idpData);
    } else {
      this.createIdp(idpData);
    }
  }

  // create IDP server call
  createIdp(idpData) {
    this.manageProductTenantManagerService.createIdpApp(idpData).subscribe(res => {
      console.log(res);
      this.toastrService.success(appConstants.messages.idpAppCreated, 'Success');
      this.requestInitiated = false;
      setTimeout(() => {
        this.closeModal(true);
      }, 1000);
    },
      error => {
        this.requestInitiated = false;
      });
  }

  // edit IDP server call
  editIdp(idpData) {
    this.manageProductTenantManagerService.editIdpApp(idpData).subscribe(res => {
      this.toastrService.success(appConstants.messages.idpAppUpdated, 'Success');
      this.requestInitiated = false;
      setTimeout(() => {
        this.closeModal(true);
      }, 1000);
    },
      error => {
        this.requestInitiated = false;
        this.addEditIdpForm.patchValue({
          defaultIdp: this.data.idpData.isDefault
        });
      });
  }

  // delete IDP 
  // modify if users associated then dont allow delete
  deleteIdpApp() {
    this.manageProductTenantManagerService.deleteIdpApp(this.data.idpData.id).subscribe(res => {
      this.toastrService.success(appConstants.messages.idpAppDeleted, 'Success');
      setTimeout(() => {
        this.closeModal(true);
      }, 300);
    },
      error => {
      });
  }
  // If the user clicks the cancel button a.k.a. the go back button, then\
  // just close the modal
  closeModal(status) {
    this.dialogRef.close(status);
  }

}
