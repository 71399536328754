export enum StatusCodes {
    SUCCESS = 200,
    NOT_FOUND = 404,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    DUPLICATE = 409,
    SOMETHING_WENT_WRONG = 500,
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    INTERNAL_SERVER_ERROR = 500,
    CONFLICT = 409
}

export enum RunningMode {
    localhost = 'localhost'
}

export enum RoleTypeEnums {
    UserRole = 'UserRole',
    APIRole = 'APIRole',
    UserRoleText = 'User Role',
    APIRoleText = 'API Role'
}

export enum RouteMethodType {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    OPTIONS = 'OPTIONS',
    HEAD = 'HEAD'
}

export enum CommonEnums {
    routes = 'routes',
    userRoute = 'userRoutes',
    apiRoute = 'apiRoutes',
    BCGOktaIdp = 'BCG IDP',
    ClientIdp = 'Client IDP',
    Valid = 'VALID',
    InValid = 'INVALID'
}

export enum ConstantDomains {
    bcg = 'bcg.com',
    bcgtools = 'bcgtools.com',
}

export enum ActionType {
    create = 'Create',
    edit = 'Edit'
}

export enum IdpApp {
    createIdpApp = 'Add Identity Provider',
    editIdpApp = 'Edit Add Identity Provider',
    ClientIdp = 'Client IDP',
    BCGOktaApp = 'BCG Okta IDP',
    bcgIdpType = 'BCGOktaIdp',
    clientIdpType = 'ClientIdp'
}

export enum EmailDomains {
    createEmailIDP = 'Add Email Domain',
    editEmailIDP = 'Edit Email Domain',
}
