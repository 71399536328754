import { environment } from '../../environments/environment';

export enum RoutePermissionType {
  AtLeastOneRole = 1,
  AllRole = 2,
  OnlyLoginRequired = 3
}

export enum AuthorizationResult {
  Success = 1,
  LoginRequired = 2,
  Unauthorised = 3
}

export const InputRegex = '^[ A-Za-z0-9_@.,;#&-]*$';
export const Email = '^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$';
export const UrlRegex = `(^(http|https)?:\/\/localhost:[0-9]{1,5}\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))|` +
  `((http|https)://)?(www.)?`
  + `[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]`
  + `{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`;

class AppUrls {
  // base urls
  swpIamBaseUrl = `${environment.swpIamBaseUrl}`;
  // iam service urls
  // tenant service urls
  getTenantsList = `${this.swpIamBaseUrl}/tenant`;
  createTenant = `${this.swpIamBaseUrl}/tenant`;
  editTenant = `${this.swpIamBaseUrl}/tenant`;
  deleteTenant = `${this.swpIamBaseUrl}/tenant`;

  // Idp Apps & Email Domains
  getIdpApp = `${this.swpIamBaseUrl}/idpapp`;
  createIdpApp = `${this.swpIamBaseUrl}/idpapp`;
  editIdpApp = `${this.swpIamBaseUrl}/idpapp`;
  deleteIdpApp = `${this.swpIamBaseUrl}/idpapp`;
  getIdpByEmail = `${this.swpIamBaseUrl}/idpapps/tenant`;
  getIdpEmailList = `${this.swpIamBaseUrl}/idpdomainmapping`;
  createEmailDomain = `${this.swpIamBaseUrl}/idpdomainmapping`;
  getEmailDomain = `${this.swpIamBaseUrl}/idpdomainmapping`;
  editEmailDomain = `${this.swpIamBaseUrl}/idpdomainmapping`;
  deleteEmailDomainByTenant = `${this.swpIamBaseUrl}/idpdomainmapping`;
  // Deprecated 
  getIdpAppTenant = `${this.swpIamBaseUrl}/idp-apps/tenant`;

  // API Keys 
  getAPIKeyList = `${this.swpIamBaseUrl}/api-key/product`;
  createAPIKey = `${this.swpIamBaseUrl}/api-key`;
  deleteAPIKey = `${this.swpIamBaseUrl}/api-key`;
}

class AppKeys {
  emailRegex = new RegExp(
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  );
  passwordRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  );
  user = 'userInfo';
  token = 'accessToken';
  storageType = 'localStorage';
}

class AppMessages {
  // User Messages
  deleteUserSuccessMessage = 'User Deleted';
  updateUserSuccessMessage = 'User Updated';
  addUserSuccessMessage = 'User Added';

  // Tenant MEssages
  tenantCreated = 'Tenant Registered Successfully';
  editTenant = 'Tenant Updated Successfully';
  tenantCreationError = 'Failure in Tenant Registration';
  tenantUpdationError = 'Tenant Edit Failed';
  tenantCreateLabel = 'Register Tenant';
  tenantEditLabel = 'Edit Tenant';
  tenantDeletedMessage = 'Tenant and its assocaited entities deleted successfully';
  // Idp Messages
  idpAppCreated = 'Idp App Created';
  idpAppUpdated = 'Idp App Updated';
  idpAppDeleted = 'Idp App Deleted';

  // Email Domain Message
  emailDomainAdded = 'Email Domain Added';
  emailDomainEdited = 'Email Domain Updated';
  emailDomainDeleted = 'Email Domain Deleted';

  // warning Messages
  idpOverride = 'An IdP already exists for email domain ${emailDomain}. \n' +
    'Are you sure you want to add a secondary IdP? \n' +
    'If you proceed, the existing IdP will be the default and the new IdP will become available as a secondary IdP for ${emailDomain}';
  subscriptionWithUserDeletion = 'users will be disassociated from the subscription. \n' +
    'Do you want to Continue?';
  // generic messages
  noDescription = 'No Description';
  lastSubscriptionDeletion = 'This was the last subscription for tenant . Do you also want to delete the tenant and associated email domain, IDP apps ?\n';


  // API Key Messages
  apiKeyCreated = 'API Key Created';
  // Delete Tenant
  tenantDelete = 'Are you sure want to delete this tenant & all its entities including - IDPs, Email Domains?'
}

class AppStorageTypes {
  local = 'localStorage';
  session = 'sessionStorage';
}

/**
 * App constants
 */
class AppConstants {
  // tslint:disable-next-line: variable-name
  private static _instance: AppConstants;
  public urls: AppUrls = new AppUrls();
  public keys: AppKeys = new AppKeys();
  public messages: AppMessages = new AppMessages();
  public storageTypes: AppStorageTypes = new AppStorageTypes();

  private constructor() {
    if (AppConstants._instance) {
      throw new Error(
        'Error: Instantiation failed: Use AppConstants.getInstance() instead of new.'
      );
    }
    AppConstants._instance = this;
  }

  public static getInstance(): AppConstants {
    return this._instance || new AppConstants();
  }
}

export const appConstants = AppConstants.getInstance();
