export class TenantsListModel {
    name: string = null;
    tenantid: string = null;
    businessUnitName: string = null;
    organizationName: string = null;
    description: string = null;
}

export class TenantsUIListModel {
    name: string = null;
    tenantid: string = null;
    businessUnit: string = null;
    organization: string = null;
    description: string = null;
}
