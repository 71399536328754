<div fxLayout="column" fxLayoutAlign="start stretch" class="manage-product-tenant-app-container">
    <div class="tabs-container">
        <div class="tenant-name">{{selectedTenantName.name}}</div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeLinkIndex" [destroyOnHide]="false" class="nav-tabs">
            <li [ngbNavItem]="tab.index" *ngFor="let tab of detailsTab" id="{{tab.name}}">
                <a ngbNavLink (click)="navigateTab(tab)">{{ tab.name }}</a>
            </li>
        </ul>
    </div>
    <router-outlet></router-outlet>
</div>