import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ChildActivationEnd } from '@angular/router';
import { TenantsUIListModel } from '../models/tenants.model';
import { ManageProductTenantManagerService } from './manage-product-tenant.service';

@Component({
  selector: 'app-product-tenant',
  templateUrl: './manage-product-tenant.component.html',
  styleUrls: ['./manage-product-tenant.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageProductTenantComponent implements OnInit {
  detailsTab = [];
  tenantId = '';
  activeLinkIndex = 1;
  selectedTenantName = new TenantsUIListModel();
  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    public manageProductTenantManagerService: ManageProductTenantManagerService
  ) {
    this.detailsTab = [
      {
        name: 'IDP & Email Domains',
        link: './details/idp-apps',
        pathname: '/details/idp-apps',
        index: 2
      }];
  }

  ngOnInit() {
    this.tenantId = this.activatedroute.snapshot.queryParamMap.get('tenantId');
    const tenantList = JSON.parse(localStorage.getItem('tenantsList'));
    this.selectedTenantName = tenantList.find(val => val.tenantId === this.tenantId);
    const tabTndex = this.detailsTab.findIndex((val) => {
      if (window.location.pathname.includes(val.pathname)) {
        return val;
      }
    });
    //this.getStratusTenant();
    this.activeLinkIndex = this.detailsTab[tabTndex].index;
    if (!this.tenantId) {
      this.router.navigate(['/dashboard/tenants']);
      return;
    }
    localStorage.setItem('tenantId', this.tenantId);
  }

  navigateTab(tabDetails) {
    this.router.navigate([tabDetails.link], {
      queryParams: { tenantId: this.tenantId },
      queryParamsHandling: 'merge'
    });
  }
}
