import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleModule } from '@ng-idle/core'; /** module for idle timeout watcher */
import { NgProgressModule } from '@ngx-progressbar/core'; /** module for incorporating loader / spinner */

// import HTTP interceptors
import { RequestInterceptor, ResponseInterceptor } from './interceptors';

// import core guards
import { AuthGuard, throwIfAlreadyLoaded } from './guards';
// import core services
import {
    LoggerService,
    StorageService,
    TokenService,
    AuthorizationService
} from './services';

@NgModule({
    imports: [
        HttpClientModule,
        NgProgressModule,
        NgIdleModule.forRoot()
    ],
    exports: [
        NgProgressModule
    ],
    providers: [
        AuthGuard,
        AuthorizationService,
        LoggerService,
        StorageService,
        TokenService,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
