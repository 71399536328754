<div class="account min-height" fxLayout="column" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title fxLayout fxLayoutAlign="center" id="account-title-forgot-password">Reset Password
    </mat-card-title>

    <mat-card-content>
      <div class="login-container">
        <form [formGroup]="resetForm" novalidate class="login-form">
          <mat-label class="fields-label required">Enter Email Address</mat-label>
          <mat-form-field appearance="fill" class="form-fields-create-edit">
            <input class="create-product-fields" matInput placeholder="Enter email" formControlName="UserName" required>
          </mat-form-field>
          <mat-error class="error-mat-container"
            *ngIf="(resetForm.controls.UserName.errors?.required && resetForm.controls.UserName.dirty)">
            <span class="error-text">
              Please enter email address
            </span>
          </mat-error>
          <mat-error class="error-mat-container"
            *ngIf="(resetForm.controls.UserName.dirty && resetForm.controls.UserName.errors?.email)">
            <span class="error-text">
              Please enter valid email address
            </span>
          </mat-error>
          <div class="header-separator">
          </div>
          <button appDebounceClick (debounceClick)="resetPassword()" [debounceTime]="500"
            class="btn-success login-button" mat-button>Next</button>
        </form>


      </div>
    </mat-card-content>
  </mat-card>
</div>