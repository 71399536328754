import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { CommonService } from '../services';
import { AppCookieService } from '../services/app.cookie.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetComponent implements OnInit {
  resetForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private appCookieService: AppCookieService,
    private commonService: CommonService,
  ) {
    this.resetForm = this.formBuilder.group({
      UserName: ['', [Validators.required, Validators.email]],
    });
  }
  ngOnInit() {
  }

  resetPassword() {
    if (this.resetForm.valid) {
      console.log(this.resetForm.value);
      const loginObj = {
        UserName: this.commonService.removeHtmlTags(this.resetForm.value.UserName),
      }
      this.loginService.resetPassword(loginObj).subscribe((res: any) => {
        console.log(res);
        if (res && res.token) {
          this.appCookieService.set('authToken', res.token)
          document.location.href = `${window.origin}/dashboard/tenants`;
        }
      },
        err => {
          console.log(err)
          this.toastrService.error('Something went wrong ', err);
        })
    } else {
      return;
    }
  }

}
