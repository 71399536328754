import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardManagerService } from '../dashboard-manager.service';
import { LocalLoggerService } from '../../services/local-logger.service';
import { ToastrService } from 'ngx-toastr';
import { ITenantUIList } from '../../interfaces/tenant.interface';
import { TenantsUIListModel } from '../../models/tenants.model';
import { appConstants } from '../../shared/config';
import { ModalAddEditTenantComponent } from '../modal-add-edit-tenant/modal-add-edit-tenant.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import for rxjs
import { ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { InputRegex } from '../../shared/config';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
})
export class TenantsComponent implements OnInit {
  searchword = '';
  sortAction = '';
  tenantsList = [];
  filteredtenantsList = [];
  sortSelected = '';
  selectedTenant: ITenantUIList = new TenantsUIListModel();
  createTenantForm: FormGroup;
  tenantActionText = '';
  copyText = 'Copy to Clipboard';
  @ViewChild('searchBox', { static: true }) ref: ElementRef;
  subjectForTenant: any;

  constructor(
    private router: Router,
    private dashboardManagerService: DashboardManagerService,
    private localLoggerService: LocalLoggerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private toastrService: ToastrService,
  ) {
    this.createTenantForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(InputRegex)]],
      businessUnit: ['', [Validators.required, Validators.pattern(InputRegex)]],
      organization: ['', [Validators.required, Validators.pattern(InputRegex)]],
      description: ['', Validators.pattern(InputRegex)],
      tenantid: [''],
    });
  }

  ngOnInit() {
    this.subjectForTenant = new BehaviorSubject(null);
    this.subjectForTenant.pipe(
      debounceTime(1000),
    ).subscribe(val => this.searchThis());
    this.getTenants();
  }

  // get tenant list from server
  getTenants() {
    this.tenantsList = [];
    this.dashboardManagerService.getTenantsList().subscribe((res: any) => {
      this.tenantsList = res.data;
      console.log('this.tenantsList', this.tenantsList)
      this.filteredtenantsList = res.data;
      localStorage.setItem('tenantsList', JSON.stringify(res.data));
      this.sortAction = 'asc';
      this.sortList();
      if (this.searchword) {
        this.searchThis();
      }
    },
      error => {
        this.localLoggerService.logError(error);
      });
  }

  // debounce for searching tenant in list
  searchThisWithDebounce(val) {
    this.subjectForTenant.next('searchThis');
  }

  // search tenant from list and copy to filtered list
  searchThis() {
    this.tenantsList = this.filteredtenantsList.filter((e) => {
      return (
        e.name.toLowerCase().includes(this.searchword.toLowerCase())
      );
    });
    if (this.sortAction === 'desc') {
      this.sortDescending();
    } else if (this.sortAction === 'asc') {
      this.sortAscending();
    }
  }

  // open modal for creation of tenant
  addTenant() {
    this.tenantActionText = appConstants.messages.tenantCreateLabel;
    this.openTenantModal(this.tenantActionText, null);
  }

  // open modal for edit of tenant
  tenantDetails(value) {
    this.tenantActionText = appConstants.messages.tenantEditLabel;
    this.openTenantModal(this.tenantActionText, value);
  }

  manageTenant(val) {
    this.router.navigate(['/details/idp-apps'], {
      queryParams: { tenantId: val.tenantId },
      queryParamsHandling: 'merge'
    });
  }

  // copy to clipboard
  copyId(text: string) {
    this.copyText = 'Copied';
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => { this.copyText = 'Copy to Clipboard'; }, 3000);
  }

  // modify Description if desc is empty
  modifyDesc(desc) {
    return (desc && desc.length) > 0 ? desc : appConstants.messages.noDescription;
  }

  // sort tenant list
  sortList() {
    if (this.sortAction && this.sortAction === 'asc') {
      this.sortAscending();
    } else if (this.sortAction && this.sortAction === 'desc') {
      this.sortDescending();
    }
  }

  sort_by_key(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  // sort tenant via ascending tenant name
  sortAscending() {
    this.sortAction = 'asc';
    // this.tenantsList = this.sort_by_key(this.tenantsList,'name');
    this.tenantsList.sort((a, b) => {
      return b.name.toUpperCase() < a.name.toUpperCase() ? 1 // if b should come earlier, push a to end
        : b.name.toUpperCase() > a.name.toUpperCase() ? -1 // if b should come later, push a to begin
          : 0;
    });
  }

  // sort tenant via descending tenant name
  sortDescending() {
    this.sortAction = 'desc';
    this.tenantsList.sort((a, b) => {
      return b.name.toUpperCase() > a.name.toUpperCase() ? 1 // if b should come earlier, push a to end
        : b.name.toUpperCase() < a.name.toUpperCase() ? -1 // if b should come later, push a to begin
          : 0;
    });
  }


  // download jwt key
  downloadKey(key, type?) {
    if (key && key.length > 1) {
      const element = document.createElement('a');
      const file = new Blob([key], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'public_key.pub';
      if (type && type === 'APIKey') {
        element.download = 'api_key.pub';
      }
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } else {
      this.toastrService.error('Jwt verification key is empty', 'Error')
    }
  }

  // open modal for create/edit product
  openTenantModal(title, editData) {
    const dialogRef: MatDialogRef<ModalAddEditTenantComponent> = this.dialog.open(
      ModalAddEditTenantComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          title,
          tenantData: editData
        }
      });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getTenants();
      }
    });
  }
}
