<div class="wrapper">
    <div class="product">
        <div class="product-name unselectable bold" (click)="goToTenants()">
            SWP-IAM
        </div>
        <div class="tabs-container">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeLinkIndex" [destroyOnHide]="false" class="nav-tabs">
                <li [ngbNavItem]="tab.index" *ngFor="let tab of tabs">
                    <a ngbNavLink [routerLink]="tab.link">
                        {{ tab.name }}
                        <div class="highlighter"></div>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="right-container">
        <div class="user-container" *ngIf="username.length > 0">
            <mat-icon class="user-icon icon-user-circle" :before></mat-icon>
            <p (mouseover)="showLogout(true)">Welcome {{username}}
            </p>
            <mat-icon (mouseover)="showLogout(true)" (mouseleave)="showLogout(false)"
                class="user-icon icon-caret-down ">
            </mat-icon>
            <mat-list class="menu-div" *ngIf="enableLogout">
                <mat-list-item [ngClass]="color1"><a (mouseover)="showLogout(true);color1='active-tab'"
                        (mouseout)="color1=''" (click)="logout()">
                        Logout</a></mat-list-item>
                <mat-list-item [ngClass]="color2"><a (mouseover)="showLogout(true); color2='active-tab'"
                        (mouseout)="color2=''" (mouseout)="color2=''" (click)="goToResetPassword()">
                        Reset password</a></mat-list-item>
            </mat-list>

        </div>
        <!-- <div class="logo"></div> -->
    </div>
</div>