import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { OktaAuthService } from '../okta-auth.service';
import { AppCookieService } from '../services/app.cookie.service';
import { LocalLoggerService } from '../services/local-logger.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-oktaauthcallback',
  templateUrl: './oktaauthcallback.component.html',
  styleUrls: ['./oktaauthcallback.component.scss'],
  providers: [OktaAuthService, AppCookieService]
})
export class OktaauthcallbackComponent implements OnInit {
  oktaObj;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localLoggerService: LocalLoggerService,
    private oktaService: OktaAuthService,
    private appCookieService: AppCookieService) {

    this.oktaService.setOkta().subscribe(res => {
      this.localLoggerService.logForDev('res finale', res);
      this.oktaObj = res;
    });

  }

  ngOnInit() {
    this.localLoggerService.logForDev('res finale this.getParam(access_token)', this.oktaObj, this.getParam('access_token'));
    // tslint:disable-next-line: no-debugger
    this.route.queryParams.subscribe(a => {
      this.localLoggerService.logForDev(a);
      const token = new URLSearchParams(a).get('access_token');
      if (token) {
        //localStorage.setItem('accessToken', token);
        //this.appCookieService.set('accessToken', token);
        const decodedJwtToken: any = jwt_decode(token);
        const cookieExpirationTime: number = decodedJwtToken && decodedJwtToken.exp && decodedJwtToken.iat
          ? (decodedJwtToken.exp - decodedJwtToken.iat)
          : 1800;
        this.appCookieService.setWithExpirationPeriod('accessToken', token, cookieExpirationTime);
        this.router.navigate(['/dashboard/tenants']);
      } else {
        document.location.href = window.origin + `/login`;
      }
    });
  }

  getParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return '0';
    }
    return results[1] || '0';
  }
}
