<div *ngIf="requestInitiated" class="loading-indicator">
    <mat-progress-spinner diameter="50" mode="indeterminate" color="accent"></mat-progress-spinner>
</div>
<div class="modal add-edit-idpapp-modal">
    <header class="modal-header">
        <span class="modal-title">{{title}}</span>
        <mat-icon class="icon-close" :before (click)="closeModal(false)"></mat-icon>
    </header>
    <mat-dialog-content class="mat-dialog-content" id="modal-body">
        <form [formGroup]="addEditIdpForm" novalidate class="add-edit-form">

            <div class="client-idp-fields">
                <mat-label class="fields-label required">IDP Name</mat-label>
                <mat-form-field appearance="fill" class="form-fields-create-edit">
                    <input class="create-product-fields" matInput placeholder="Enter" formControlName="appName"
                        required>
                </mat-form-field>
                <mat-error class="error-mat-container"
                    *ngIf="(addEditIdpForm.controls.appName.errors?.required && addEditIdpForm.controls.appName.dirty) 
                || (addEditIdpForm.controls.appName.pristine && addEditIdpForm.controls.appName.touched && addEditIdpForm.controls.appName.errors?.required)">
                    <span class="error-text">
                        Please enter idp name
                    </span>
                </mat-error>
                <mat-label class="fields-label required">Display Name</mat-label>
                <mat-form-field appearance="fill" class="form-fields-create-edit">
                    <input class="create-product-fields" matInput placeholder="Enter" formControlName="displayName"
                        required>
                </mat-form-field>
                <mat-error class="error-mat-container"
                    *ngIf="(addEditIdpForm.controls.displayName.errors?.required && addEditIdpForm.controls.displayName.dirty) 
                || (addEditIdpForm.controls.displayName.pristine && addEditIdpForm.controls.displayName.touched && addEditIdpForm.controls.displayName.errors?.required)">
                    <span class="error-text">
                        Please enter display name
                    </span>
                </mat-error>
                <mat-label class="fields-label required">Sso Url</mat-label>
                <mat-form-field appearance="fill" class="form-fields-create-edit-textarea">
                    <textarea rows="3" class="create-product-fields-textarea" matInput placeholder="Enter"
                        formControlName="ssoUrl" required> </textarea>
                </mat-form-field>
                <mat-error class="error-mat-container"
                    *ngIf="(addEditIdpForm.controls.ssoUrl.errors?.required && addEditIdpForm.controls.ssoUrl.dirty) 
                || (addEditIdpForm.controls.ssoUrl.pristine && addEditIdpForm.controls.ssoUrl.touched && addEditIdpForm.controls.ssoUrl.errors?.required)">
                    <span class="error-text">
                        Please enter sso url
                    </span>
                </mat-error>
                <mat-error class="error-mat-container"
                    *ngIf="(addEditIdpForm.controls.ssoUrl.errors?.pattern && addEditIdpForm.controls.ssoUrl.dirty)">
                    <span class="error-text">
                        Please enter valid sso url
                    </span>
                </mat-error>
                <mat-label class="fields-label required">Saml Issuer</mat-label>
                <mat-form-field appearance="fill" class="form-fields-create-edit-textarea">
                    <input class="create-product-fields" matInput placeholder="Enter" formControlName="samlIssuer"
                        required>
                </mat-form-field>
                <mat-error class="error-mat-container"
                    *ngIf="(addEditIdpForm.controls.samlIssuer.errors?.required && addEditIdpForm.controls.samlIssuer.dirty) 
                || (addEditIdpForm.controls.samlIssuer.pristine && addEditIdpForm.controls.samlIssuer.touched && addEditIdpForm.controls.samlIssuer.errors?.required)">
                    <span class="error-text">
                        Please enter saml issuer
                    </span>
                </mat-error>
                <mat-label class="fields-label required">Certificate</mat-label>
                <mat-form-field appearance="fill" class="form-fields-create-edit-textarea">
                    <textarea rows="3" class="create-product-fields-textarea" matInput placeholder="Enter"
                        formControlName="appCertificate" required> </textarea>
                </mat-form-field>
                <mat-error class="error-mat-container"
                    *ngIf="(addEditIdpForm.controls.appCertificate.errors?.required && addEditIdpForm.controls.appCertificate.dirty) 
                || (addEditIdpForm.controls.appCertificate.pristine && addEditIdpForm.controls.appCertificate.touched && addEditIdpForm.controls.appCertificate.errors?.required)">
                    <span class="error-text">
                        Please enter certificate
                    </span>
                </mat-error>
                <mat-label class="fields-label">Reference Notes</mat-label>
                <mat-form-field appearance="fill" class="form-fields-create-edit-textarea">
                    <textarea rows="3" class="create-product-fields-textarea" matInput
                        placeholder="Write something about this IDP for future reference"
                        formControlName="description"> </textarea>
                </mat-form-field>
                <!-- <div class="encryption-setting">
                    <div class="idp-switches">
                        <div class="toggle-encryption-container">
                            <mat-label class="fields-label"> Set Default</mat-label>
                            <mat-icon matSuffix class="informationIcons"
                                matTooltip="By enabling the flag this IDP will be used when email domains dont match"
                                matTooltipPosition="right">helpoutline</mat-icon>
                            <div class="custom-control custom-switch"
                                [ngClass]="{'toggle-disabled' : isEncryptionEnabledClass}">
                                <input type="checkbox" (change)="isEncryptionEnabledToggle($event)"
                                    class="custom-control-input" formControlName="isDefault" id="isDefault" />
                                <label class="custom-control-label" for="isDefault"></label>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </form>
    </mat-dialog-content>
    <footer class="action-button-layout modal-footer">
        <button *ngIf="editAction" mat-mini-fab class="delete-button" appDebounceClick (debounceClick)="deleteIdpApp()"
            [debounceTime]="500">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-stroked-button class="cancel-button" id="modal-action-button"
            (click)="closeModal(false)">Cancel</button>
        <button mat-flat-button class="save-button" [disabled]="!addEditIdpForm.valid" appDebounceClick
            (debounceClick)="saveIdp()" [debounceTime]="500" color="bcg-button" id="modal-cancel-button">Save</button>
    </footer>
</div>