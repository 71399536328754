import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'; /* check the routing state */
import { AuthorizationService } from '../services'; /* get app url and app constant  */
import { environment } from '../../../environments/environment';
import { LocalLoggerService } from '../../services/local-logger.service';
import { AppCookieService } from '../../services/app.cookie.service';


/**
 * Class validates the token , If authenticated redirect it to the specified path else force the application
 * back to login page
 */
@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private loggerService: LocalLoggerService,
        private authorizeService: AuthorizationService,
        private appCookieService: AppCookieService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        //this.loggerService.logForDev('canActivate ', localStorage.getItem('authToken'), route,
        this.loggerService.logForDev('canActivate ', this.appCookieService.get('authToken'), route,
            this.authorizeService.authorize(route.data.routePermission));
        //if ((localStorage.getItem('authToken') && localStorage.getItem('authToken') != null)
        if ((this.appCookieService.get('authToken') && this.appCookieService.get('authToken') != null)
            //&& (localStorage.getItem('authToken') !== undefined)) {
            && (this.appCookieService.get('authToken') !== undefined)) {
            return true;
        } else {
            document.location.href = window.origin + `/login`;
            return false;
        }
        // return true;
    }
}
