import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { IIdpApp, IEmailDomain } from '../../interfaces/product-tenant.interface';
import { ModalAddEditEmailComponent } from '../modal-add-edit-email/modal-add-edit-email.component';
import { ModalAddEditIdpComponent } from '../modal-add-edit-idp/modal-add-edit-idp.component';
import { ManageProductTenantManagerService } from '../manage-product-tenant.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IdpApp, EmailDomains } from '../../shared/utils/enums';
import { IdpAppTypeName } from '../../shared/pipes/idpAppTypeName.pipe';
import { IdpAppListsModel, IdpAppModel } from '../../models/product-tenant.model';

@Component({
  selector: 'app-idp-apps',
  templateUrl: './idp-apps.component.html',
  styleUrls: ['./idp-apps.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [IdpAppTypeName]
})
export class IdpAppsComponent implements OnInit, AfterViewInit {
  displayedColumnsIDP = ['appName', 'samlIssuer', 'createdOn', 'referenceNotes', 'action'];
  displayedColumnsEmail = ['domain', 'idpAppName', 'referenceNotes', 'updatedOn', 'action'];
  dataSourceIdp: MatTableDataSource<IIdpApp> = new MatTableDataSource();
  dataSourceEmail: MatTableDataSource<IEmailDomain> = new MatTableDataSource();
  tenantId = '';
  bcgIdpExist = false;
  idpAppList = [];
  idpAppbj = new IdpAppListsModel();
  step1View = false;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    public manageProductTenantManagerService: ManageProductTenantManagerService
  ) {
  }

  ngOnInit() {
    this.tenantId = this.activatedroute.snapshot.queryParamMap.get('tenantId');
    this.listIdpApps();
  }

  ngAfterViewInit() {
    this.dataSourceIdp.sort = this.sort;
    this.dataSourceEmail.sort = this.sort;
  }

  listIdpApps() {
    this.manageProductTenantManagerService.getIdpsByTenant(this.tenantId).subscribe(res => {
      this.dataSourceIdp = new MatTableDataSource(res.data);
      // this.dataSourceEmail = new MatTableDataSource(res.data.emailDomains);
      if (res.data.length === 0) {
        this.step1View = true;
      } else {
        this.step1View = false;
      }
      this.dataSourceIdp.sort = this.sort;
      this.idpAppList = [];
      this.prepareIDPData(res.data);
      this.getEmailDomains();
    });
  }

  getEmailDomains() {
    this.manageProductTenantManagerService.getEmailDomain(this.tenantId).subscribe(res => {
      res.forEach(element => {
        const idp = this.idpAppList.find(a => a.idpAppId === element.idpAppId);
        if (idp) {
          element.idpAppName = idp.appName;
          element.referenceNotes = idp.referenceNotes;
        }
      });
      console.log(res);
      this.dataSourceEmail = new MatTableDataSource(res);
      this.dataSourceEmail.sort = this.sort;
    });
  }

  // add idp app
  addIdpApp() {
    const title = IdpApp.createIdpApp;
    this.openIdpModal(title, null);
  }

  // edit idp app
  manageIdpApp(val) {
    const title = IdpApp.editIdpApp;
    this.openIdpModal(title, val);
  }

  // add email domain
  addEmailDomain() {
    const title = EmailDomains.createEmailIDP;
    const emailDomainList = this.addEmailDomainsList();
    this.openEmailModal(title, null, emailDomainList);
  }

  // edit email Domain
  manageEmail(val) {
    const title = EmailDomains.editEmailIDP;
    const emailDomainList = this.addEmailDomainsList();
    this.openEmailModal(title, val, emailDomainList);
  }

  // add email Domains to list for modal
  addEmailDomainsList() {
    const emailDomainList = [];
    this.dataSourceEmail.data.forEach(val => {
      emailDomainList.push({ domain: val.domain, id: val.id });
    });
    return emailDomainList;
  }

  // to check if Bcg idp already exist
  checkBcgIdpExist() {
  }

  // concatinate idp app names for email Domain List
  concatIDP(idpVals) {
    let idpNames = [];
    idpVals.forEach(element => {
      if (element.isDefault) {
        idpNames.push(`<label class="referenceNotes"> ` + element.idpAppName + '<span class="grayColored">(Default)</span>' + '</label>');
      } else {
        idpNames.push(`<label class="referenceNotes"> ` + element.idpAppName + '</label>');
      }
    });
    idpNames = idpNames.sort(function (a, b) {
      return a.idpAppId - b.idpAppId;
    });
    return idpNames.join('\n');
  }

  concatIDPUserCount(idpVals) {
    let idpNames = [];
    idpVals.forEach(element => {
      if (element.userCount === 0) {
        idpNames.push('<label class="referenceNotes redColored">' + element.userCount + '</label>');
      } else {
        idpNames.push('<label class="referenceNotes greenColored">' + element.userCount + '</label>');
      }
    });
    idpNames = idpNames.sort(function (a, b) {
      return a.idpAppId - b.idpAppId;
    });
    return idpNames.join('\n');
  }

  concatReferenceNotes(idpVals) {
    let idpNames = [];
    idpVals.forEach(element => {
      idpNames.push('<label class="referenceNotes" title="' + element.description + '" matTooltipPosition="after">' + (element.description ? element.description : '-') + '</label>');
    });
    idpNames = idpNames.sort(function (a, b) {
      return a.idpAppId - b.idpAppId;
    });
    return idpNames.join('\n');
  }

  // add idp Name, UserCount & id into copy object for Email domain popup
  prepareIDPData(idpAppsData) {
    idpAppsData.forEach((element: IdpAppModel) => {
      this.idpAppbj = new IdpAppListsModel();
      this.idpAppbj.appName = element.appName;
      this.idpAppbj.referenceNotes = element.description;
      this.idpAppbj.idpAppId = element.id;
      this.idpAppList.push(this.idpAppbj);
    });
  }

  // open edit , create idp modal
  openIdpModal(title, editData) {

    const dialogRef: MatDialogRef<ModalAddEditIdpComponent> = this.dialog.open(
      ModalAddEditIdpComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          title,
          tenantId: this.tenantId,
          idpData: editData ? editData : null,
          bcgIDPExist: this.bcgIdpExist
        }
      });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.listIdpApps();
      }
    });
  }

  // open edit, create email modal
  openEmailModal(title, editData, emailDomainList) {
    const dialogRef: MatDialogRef<ModalAddEditEmailComponent> = this.dialog.open(
      ModalAddEditEmailComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          title,
          tenantId: this.tenantId,
          emailDomainData: editData,
          idpList: this.idpAppList,
          emailDomainList: emailDomainList,
        }
      });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getEmailDomains();
      }
    });
  }

  getFormattedDate(row) {
    let date = row.modifiedOn ? row.modifiedOn : row.createdOn;
    if (date) {
      let options: any = { year: 'numeric', month: 'short', day: 'numeric' };
      let today = new Date(date);
      return today.toLocaleDateString("en-US", options);
    } else {
      return null;
    }
  }

  navigateTab() {
    let link = document.getElementById('User Management');
    //@ts-ignore
    link.children[0].click();
    // this.router.navigate(['./details/users'], {
    //   queryParams: { tenantId: this.tenantId },
    //   queryParamsHandling: 'merge'
    // });
  }
}
