import { FormControl, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Custom validator
 */
export class CustomValidators {
    /**
     * validates inputs controls text match
     */
    static areEqual(group: FormGroup) {
        let val;
        let valid = true;

        for (const name in group.controls) {
            if (val === undefined) {
                val = group.controls[name].value;
            } else {
                if (val !== group.controls[name].value) {
                    valid = false;
                    break;
                }
            }
        }

        if (valid) {
            return null;
        }

        return {
            areEqual: true
        };
    }

    /*validates email business rule
    */
    static emailDomainValidator(control: FormControl) {
        const email = control.value;
        if (email && email.indexOf('@') !== -1) {
            const [_, domain] = email.split('@');
            if (domain !== 'bcg.com') {
                return {
                    emailDomain: {
                        parsedDomain: domain
                    }
                };
            }
        }
        return null;
    }

    static pattern(reg: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const value = control.value as string ? control.value as string : '';
            return value.match(reg) ? null : { pattern: { value } };
        };
    }
}
