import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { appConstants } from '../shared';

@Injectable()
export class ManageProductTenantManagerService {
  loading: boolean;
  constructor(
    private http: HttpClient
  ) {
  }

  // Subscription server call ends

  // IDP server call starts
  createIdpApp(createData) {
    return this.http.post(appConstants.urls.createIdpApp, createData);
  }

  editIdpApp(editData) {
    return this.http.put(appConstants.urls.editIdpApp, editData);
  }

  getIdpsByTenant(tenantId) {
    return this.http.get(appConstants.urls.getIdpApp + '?tenantId=' + tenantId)
      .pipe(
        map((response: any) => ({
          data: response
        })
        )
      );
  }

  deleteIdpApp(idpId) {
    return this.http.delete(appConstants.urls.deleteIdpApp + '?idpAppId=' + idpId);
  }

  // IDP server call ends

  // Email Domains server call starts

  getIdpByEmail(tenantId, emailDomain) {
    return this.http.get(appConstants.urls.getIdpByEmail + '/' + tenantId + '/email-domain/' + emailDomain)
      .pipe(
        map((response: any) => ({
          data: response,
          total: response.length
        })
        )
      );
  }

  getEmailDomain(tenantId) {
    return this.http.get(appConstants.urls.createEmailDomain + '?tenantId=' + tenantId)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  createEmailDomain(emailDomainData) {
    return this.http.post(appConstants.urls.createEmailDomain, emailDomainData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editEmailDomain(emailDomainData) {
    return this.http.put(appConstants.urls.editEmailDomain, emailDomainData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteEmailDomain(emailDomainID) {
    return this.http.delete(appConstants.urls.deleteEmailDomainByTenant + '?mappingId=' + emailDomainID)
      .pipe(
        map((response: any) => {
          return response;
        }
        )
      );
  }
}
